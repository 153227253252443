import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Pager from '../components/Pager';
import BookingContext from '../contexts/BookingContext';

const convertAlgoliaBookingModel = booking => {
	booking._id = booking.objectID;
	return booking;
};

const BookingList = ({ query }) => {
	const history = useHistory();
	const { bookingList: bookings, fetchBookingsAtPage, loading } = useContext(BookingContext);

	useEffect(() => {
		fetchBookingsAtPage({ query, page: 0 });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query]);

	const nextPage = nextPage => {
		fetchBookingsAtPage({ query, page: nextPage });
	};

	const prevPage = prevPage => {
		fetchBookingsAtPage({ query, page: prevPage });
	};

	const bookingListContent = (() => {
		if (loading) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						Loading
					</td>
				</tr>
			);
		}
		if (!bookings.length) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						It appears there are no bookings
					</td>
				</tr>
			);
		}
		return bookings.map(convertAlgoliaBookingModel).map(booking => (
			<tr key={booking._id} onClick={() => history.push(`/bookings/${booking._id}`)}>
				<td>{booking.code}</td>
				<td>{booking.trip.title}</td>
				<td>
					{booking.guide.first_name} {booking.guide.last_name}
				</td>
				<td colSpan={2}>
					{booking.lead.first_name || booking.lead.last_name
						? `${booking.lead.first_name} ${booking.lead.last_name}`
						: booking.lead.email}
				</td>
			</tr>
		));
	})();

	return (
		<>
			{bookingListContent}
			<tr className="pager">
				<td colSpan={12}>
					<Pager onPrevPage={prevPage} onNextPage={nextPage} />
				</td>
			</tr>
		</>
	);
};

export default BookingList;
