/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import algoliasearch from 'algoliasearch/lite';

import AuthContext from './AuthContext';
import * as BookingService from '../data/bookings';

import { fetchLA2Booking } from '../data/bookings';
import { fetchInvoicesFromBooking } from '../data/invoices';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;

const BookingContext = createContext({
	fetchBookingByCode: async () => {},
	bookingList: [],
	readyToBillList: [],
	fetchBookingsAtPage: () => {},
	fetchReadyToBillBookingsAtPage: () => {},
	loading: true,
	loadingBookingDetails: true,
	selectedBooking: null,
	selectBooking: () => {},
	deselectBooking: () => {},
});

export default BookingContext;

const PAGE_SIZE = 20;

export const BookingProvider = ({ children }) => {
	const { credentials } = useContext(AuthContext);
	const history = useHistory();

	const [bookingList, setBookingList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [loadingBookingDetails, setLoadingBookingDetails] = useState(true);
	const [selectedBooking, setSelectedBooking] = useState(null);

	const fetchBookingByCode = async code => {
		try {
			const booking = await BookingService.fetchBookingByCode(code, credentials);
			return booking;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const selectBooking = async bookingId => {
		if (!bookingId) return setSelectedBooking(null);
		setLoadingBookingDetails(true);
		try {
			const [la2Booking, invoices] = await Promise.all([
				fetchLA2Booking(bookingId, credentials),
				fetchInvoicesFromBooking(bookingId, credentials),
			]);
			const booking = { ...la2Booking.booking, invoices };
			setSelectedBooking(booking);
		} catch (e) {
			setSelectedBooking(null);
			console.error(e);
		}
		setLoadingBookingDetails(false);
	};

	const deselectBooking = () => {
		setSelectedBooking(null);
		setLoadingBookingDetails(true);
		history.push('/bookings');
	};

	const fetchBookingsAtPage = async ({ query, page, filters = '' }) => {
		setLoading(true); // -> this makes the page flicker and scroll up

		const client = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);
		const index = client.initIndex('es_la_sold_bookings');
		const bookingList = await index
			.search(query, {
				page,
				hitsPerPage: PAGE_SIZE,
				filters,
			})
			.then(({ hits }) => hits);
		setBookingList(bookingList);
		setLoading(false);
	};

	const contextValues = {
		fetchBookingByCode,
		bookingList,
		loading,
		fetchBookingsAtPage,
		selectBooking,
		deselectBooking,
		loadingBookingDetails,
		selectedBooking,
	};

	return <BookingContext.Provider value={contextValues}>{children}</BookingContext.Provider>;
};
