import React from 'react';
import './NumericInput.css';
import Input from '@exploreshare/everest/base/Input';

const NumericInput = ({ label, value, isValid, handleChange, ...rest }) => {
	const onChange = event => {
		const value = event.target.value;
		if (value.match(/^[0-9]*(\.[0-9]{0,2})?$/)) {
			handleChange(event);
		}
	};

	return (
		<Input
			label={label}
			placeholder={label}
			value={value}
			errorLabels={['Invalid date']}
			handleChange={onChange}
			isValid={isValid}
			type="number"
			step="0.01"
			{...rest}
		/>
	);
};

export default NumericInput;
