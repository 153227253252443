import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import withNano from '@exploreshare/everest/withNano';

import Text from '@exploreshare/everest/base/Text';
import Logo from '@exploreshare/everest/icons/Logo';
import styles from './styles';
import AuthContext from '../../contexts/AuthContext';

const NavBar = ({ classes }) => {
	const { logOut } = useContext(AuthContext);
	return (
		<>
			<nav className={classes.root}>
				<div className={classes.content}>
					<Link to="/" className={classes.logo}>
						<Logo />
						<Text bold size={16} color="black">
							Explore-Share
						</Text>
					</Link>
					<div className={classes.leftContent}>
						<span onClick={logOut} className={classes.logout}>
							Logout
						</span>
					</div>
				</div>
			</nav>
		</>
	);
};
NavBar.propTypes = {
	classes: PropTypes.object,
	isMobile: PropTypes.bool,
};
NavBar.displayName = 'NavBar';
export default withNano(styles)(NavBar);
