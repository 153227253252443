import React, { createContext, useContext } from 'react';

import AuthContext from './AuthContext';
import * as AccountantService from '../data/accountant';

import downloadFile from '../util/downloadFile';
import { format } from 'date-fns';
import { useNotifications } from './NotificationContext';

const AccountantContext = createContext({
	downloadSalesCSV: () => {},
	downloadClientsCSV: () => {},
});

export default AccountantContext;

export const AccountantProvider = ({ children }) => {
	const { credentials } = useContext(AuthContext);
	const notify = useNotifications();

	const downloadSalesCSV = async ({ from, to }) => {
		try {
			const csv = await AccountantService.downloadSalesCSV({ from, to }, credentials);
			downloadFile({
				content: csv,
				filename: `accountant-sales-${format(new Date(from || null), 'yyyyMMdd')}-${format(
					new Date(to || null),
					'yyyyMMdd'
				)}.csv`,
			});
		} catch (err) {
			console.error(err);
			notify.error('The dates selected are invalid');
		}
	};

	const downloadClientsCSV = async ({ from, to }) => {
		try {
			const csv = await AccountantService.downloadClientsCSV({ from, to }, credentials);
			downloadFile({
				content: csv,
				filename: `accountant-clients-${format(new Date(from || null), 'yyyyMMdd')}-${format(
					new Date(to || null),
					'yyyyMMdd'
				)}.csv`,
			});
		} catch (err) {
			console.error(err);
			notify.error('The dates selected are invalid');
		}
	};

	const contextValues = { downloadSalesCSV, downloadClientsCSV };

	return <AccountantContext.Provider value={contextValues}>{children}</AccountantContext.Provider>;
};
