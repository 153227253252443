import React, { useState, useContext, useRef, useEffect } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';

import Input from '@exploreshare/everest/base/Input';
import EverestLink from '@exploreshare/everest/base/Link';
import Grid from '@exploreshare/everest/base/Grid';
import Button from '@exploreshare/everest/base/Button';
import Header from '@exploreshare/everest/base/Header';
import Text from '@exploreshare/everest/base/Text';

import NumericInput from '../../components/NumericInput';

import InvoiceContext from '../../contexts/InvoiceContext';
import { useNotifications } from '../../contexts/NotificationContext';

const isInvoiceIdValid = code => /^F \d{2}-\d{2}-\d{4}$/.test(code);

const InvoiceCreation = () => {
	const {
		createCreditNote,
		searchInvoice: searchInvoiceAPI,
		searchCreditNotesFromInvoice,
		getInvoicePreview,
		htmlInvoicePreview,
		apiErrorMessage,
	} = useContext(InvoiceContext);
	const notify = useNotifications();
	const history = useHistory();
	const location = useLocation();

	const [staticInvoiceCode, setStaticInvoiceCode] = useState();
	const [invoiceCode, setInvoiceCode] = useState('');
	const [freezeInvoiceCode, setFreezeInvoiceCode] = useState(false);
	const [invoice, setInvoice] = useState(null);
	const [creditNotes, setCreditNotes] = useState([]);
	const [amount, setAmount] = useState('');

	const invoiceIdRef = useRef();

	const isAmountCorrect = amount => {
		const creditNotesTotalAmount = creditNotes.reduce((memo, cn) => memo + cn.amount.full, 0);
		return (
			!isNaN(parseFloat(amount)) &&
			parseFloat(amount) > 0 &&
			parseFloat(amount) <= parseFloat(invoice.amount.full.toFixed(2)) - creditNotesTotalAmount
		);
	};

	const isInvoiceFullyCancelled = () => {
		const creditNotesTotalAmount = creditNotes.reduce((memo, cn) => memo + cn.amount.full, 0);
		return Math.abs(parseFloat(invoice.amount.full.toFixed(2)) - creditNotesTotalAmount) < 0.1;
	};

	const isFormCorrect = () => !isInvoiceFullyCancelled() && (!amount || isAmountCorrect(amount));

	const searchInvoice = async ({ invoiceCode }) => {
		const [invoice, creditNotes] = await Promise.all([
			searchInvoiceAPI(invoiceCode),
			searchCreditNotesFromInvoice(invoiceCode),
		]);
		if (!invoice) {
			notify.error('The invoice does not exist');
			return setInvoice(null);
		}
		setInvoice(invoice);
		setCreditNotes(creditNotes);
		return invoice;
	};

	useEffect(() => {
		const { search } = location;
		const queryParams = new URLSearchParams(search);
		const queryParamInvoiceCode = queryParams.get('invoice');
		const _searchInvoice = async () => await searchInvoice({ invoiceCode: queryParamInvoiceCode });

		if (queryParamInvoiceCode && isInvoiceIdValid(queryParamInvoiceCode)) {
			setStaticInvoiceCode(queryParamInvoiceCode);
			setFreezeInvoiceCode(true);
			_searchInvoice();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		if (!invoiceCode) return setInvoice(null);
		if (!isInvoiceIdValid(invoiceCode)) {
			setInvoice(null);
			return invoiceIdRef.current && invoiceIdRef.current.validate();
		}
		searchInvoice({ invoiceCode });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceCode]);

	useEffect(() => {
		if (!invoice) return;
		if (!isFormCorrect()) return;
		getInvoicePreview({
			invoice: invoice._id,
			type: invoice.type.replace('invoice/', 'cn/'),
			amount: amount ? parseFloat(amount) : undefined,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount, invoice]);

	const generateCreditNote = async () => {
		if (!invoice) return;
		if (!isFormCorrect()) return;
		await createCreditNote({
			invoice: invoice._id,
			type: invoice.type.replace('invoice/', 'cn/'),
			amount: amount ? parseFloat(amount) : undefined,
		});
		history.push('/invoices');
	};

	return (
		<>
			<table className="guide-screen__title-table">
				<tbody>
					<tr>
						<td>
							<Header>Create credit note</Header>
						</td>
						<td style={{ verticalAlign: 'top', textAlign: 'right' }}>
							<Link
								onClick={e => {
									e.preventDefault();
									history.goBack();
								}}
								component={EverestLink}>
								&larr; Go back
							</Link>
						</td>
					</tr>
				</tbody>
			</table>

			<Grid container>
				<Grid item xs={4} noPaddingX style={{ paddingRight: 16 }}>
					<Input
						label="Invoice number"
						placeholder="ABC123"
						isValid={isInvoiceIdValid(staticInvoiceCode || invoiceCode)}
						value={staticInvoiceCode || invoiceCode}
						errorLabels={['Invalid invoice code']}
						handleChange={event => setInvoiceCode(event.target.value)}
						ref={invoiceIdRef}
						disabled={freezeInvoiceCode}
					/>
					{invoice && (
						<>
							<NumericInput
								label={`Amount (${invoice.currency})`}
								placeholder="0.00"
								isValid={!amount || isAmountCorrect(amount)}
								value={amount}
								errorLabels={['Invalid amount']}
								handleChange={e => setAmount(e.target.value)}
								helpLabels={['Automatically calculated if empty']}
							/>
							{creditNotes.length > 0 ? (
								<Text size="small" color="red">
									There {creditNotes.length > 1 ? 'are' : 'is'} already {creditNotes.length} credit
									note{creditNotes.length > 1 ? 's' : ''} created for a total of {invoice.currency}{' '}
									{creditNotes.reduce((memo, cn) => memo + cn.amount.full, 0)}.
								</Text>
							) : null}
							<Button
								style={{ marginTop: '12px' }}
								fullWidth
								disabled={isInvoiceFullyCancelled() || (amount && !isAmountCorrect(amount))}
								onClick={generateCreditNote}>
								Create invoice
							</Button>
						</>
					)}
				</Grid>
				<Grid item xs={8} noPaddingX>
					<h4>Credit note preview</h4>
					{htmlInvoicePreview && isFormCorrect() ? (
						<div dangerouslySetInnerHTML={{ __html: htmlInvoicePreview }} />
					) : (
						<>
							<Text>Fill out the details to see the invoice preview</Text>
							{apiErrorMessage && (
								<>
									<hr style={{ margin: '10px 0 20px' }} />
									<Text>Can't preview this invoice:</Text>
									<Text size={24} color="#d62b2b">
										{apiErrorMessage}
									</Text>
								</>
							)}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default InvoiceCreation;
