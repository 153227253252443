import React, { useState, useEffect } from 'react';

import Text from '@exploreshare/everest/base/Text';
import Arrow from '@exploreshare/everest/icons/Arrow';

import './Pager.css';

const Pager = ({ onPrevPage, onNextPage, pagesCount }) => {
	const [page, setPage] = useState(0);

	useEffect(() => {
		setPage(0);
	}, [pagesCount]);

	const isOnLastPage = () => pagesCount && page + 1 >= parseInt(pagesCount, 10);

	const onPrev = () => {
		if (page === 0) return;
		if (onPrevPage) onPrevPage(page - 1);
		setPage(page - 1);
	};

	const onNext = () => {
		if (isOnLastPage()) return;
		if (onNextPage) onNextPage(page + 1);
		setPage(page + 1);
	};

	return (
		<div className="container">
			<div className="prev-arrow icon-button">
				<Arrow size="sm" onClick={onPrev} disabled={page === 0} />
			</div>
			<Text color="grey" inline className="label">
				page {page + 1}
				{pagesCount ? `/${parseInt(pagesCount, 10)}` : ''}
			</Text>
			<div className="icon-button">
				<Arrow size="sm" onClick={onNext} disabled={isOnLastPage()} />
			</div>
		</div>
	);
};

export default Pager;
