import React, { useState, useEffect, useContext } from 'react';
import './Guides.css';

import { useHistory } from 'react-router-dom';

import Image from '@exploreshare/everest/base/Image';

import Pager from '../components/Pager';
import GuideContext from '../contexts/GuideContext';

const ROWS_PER_PAGE = 20;

const escapeRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const GuideList = ({ query }) => {
	const { guideList: guides } = useContext(GuideContext);
	const history = useHistory();
	const [page, setPage] = useState(0);

	const nextPage = nextPage => setPage(nextPage);
	const prevPage = prevPage => setPage(prevPage);

	useEffect(() => setPage(0), [query]);

	const regex = new RegExp(escapeRegExp(query), 'i');
	const filteredGuides = guides.filter(g => {
		if (!query.trim()) return true;
		return [g.first_name, g.last_name, g.email, g._id.toString()].reduce(
			(m, v) => m || regex.test(v),
			false
		);
	});

	const guideListContent = (() => {
		if (!filteredGuides.length) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						It appears there are no guides
					</td>
				</tr>
			);
		}
		const pagedGuideList = filteredGuides.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE);
		return pagedGuideList.map(guide => (
			<tr key={guide._id} onClick={() => history.push(`/guides/${guide._id}`)}>
				<td>
					<Image
						alt={`${guide.first_name} ${guide.last_name}`}
						className="avatar-image"
						rounded
						src={guide.avatar_url || 'https://unsplash.it/80/80'}
					/>
				</td>
				<td>{guide._id}</td>
				<td>{guide.first_name}</td>
				<td>{guide.last_name}</td>
				<td colSpan={2}>{guide.email}</td>
			</tr>
		));
	})();

	return (
		<>
			{guideListContent}
			<tr className="pager">
				<td colSpan={12}>
					<Pager onPrevPage={prevPage} onNextPage={nextPage} />
				</td>
			</tr>
		</>
	);
};

export default GuideList;
