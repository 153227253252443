import React from 'react';
import './DateInput.css';
import Input from '@exploreshare/everest/base/Input';

const DateInput = ({ label, value, isValid, onChange }) => (
	<Input
		label={label}
		placeholder={label}
		value={value || ''}
		errorLabels={['Invalid date']}
		handleChange={onChange}
		isValid={isValid}
		type="date"
	/>
);

export default DateInput;
