/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from 'react';

import algoliasearch from 'algoliasearch/lite';

import InvoiceContext from './InvoiceContext';

import * as InvoiceService from '../data/invoices';
import AuthContext from './AuthContext';
import { useNotifications } from './NotificationContext';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;

const ReadyToBillContext = createContext({
	readyToBillList: [],
	loading: true,
	creatingInvoices: false,
	apiErrorMessage: '',
	fetchReadyToBillBookingsAtPage: () => {},
	generateTripCommissionInvoices: () => {},
});

export default ReadyToBillContext;

const PAGE_SIZE = 20;

export const ReadyToBillProvider = ({ children }) => {
	const { credentials } = useContext(AuthContext);
	const { invoiceList, fetchInvoices, loading: fetchingInvoices } = useContext(InvoiceContext);
	const notify = useNotifications();

	const [loading, setLoading] = useState(true);
	const [loadingFilter, setLoadingFilter] = useState(true);
	const [readyToBillList, setReadyToBillList] = useState([]);
	const [readyToBillFilter, setReadyToBillFilter] = useState(null);
	const [lastFetchedPage, sestLastFetchedPage] = useState(-1);
	const [lastFetchedQuery, setLastFetchedQuery] = useState('');
	const [lastFrom, setLastFrom] = useState();
	const [lastTo, setLastTo] = useState();
	const [creatingInvoices, setCreatingInvoices] = useState(false);
	const [apiErrorMessage, setAPIErrorMessage] = useState('');

	const generateReadyToBillFilter = () => {
		setLoadingFilter(true);
		setReadyToBillFilter(null);

		const bookingsWithGuideInvoice = invoiceList.reduce((memo, i) => {
			if (i.type === 'invoice/trip-commission') {
				memo.push(i.booking._id);
			}
			return memo;
		}, []);

		const filter = bookingsWithGuideInvoice
			.map(b => `NOT objectID:${b}`)
			.filter(Boolean)
			.join(' AND ');
		setReadyToBillFilter(filter);
		setLoadingFilter(false);
	};

	useEffect(() => {
		if (!loadingFilter && lastFetchedPage >= 0) {
			fetchReadyToBillBookingsAtPage(lastFetchedQuery, lastFrom, lastTo, lastFetchedPage);
		}
	}, [loadingFilter]);

	useEffect(() => {
		fetchInvoices();
	}, []);

	useEffect(() => {
		if (!fetchingInvoices) generateReadyToBillFilter();
	}, [fetchingInvoices]);

	const fetchReadyToBillBookingsAtPage = async (query, from, to, page) => {
		setLoading(true); // -> this makes the page flicker and scroll up
		setLastFetchedQuery(query);
		sestLastFetchedPage(page);
		setLastFrom(from);
		setLastTo(to);

		if (readyToBillFilter === null) return; // The filter is not yet ready, this function will be called again when the filter is created

		const filters = [`paid_date > ${from}`, `paid_date < ${to}`, readyToBillFilter]
			.filter(Boolean)
			.join(' AND ');

		const client = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);
		const index = client.initIndex('es_la_sold_bookings');
		const readyToBillList = await index
			.search(query, {
				page,
				hitsPerPage: PAGE_SIZE,
				filters,
			})
			.then(({ hits }) => hits);
		setReadyToBillList(readyToBillList);
		setLoading(false);
	};

	const generateTripCommissionInvoices = async body => {
		setCreatingInvoices(true);
		try {
			const { notCreated } = await InvoiceService.generateTripCommissionInvoices(body, credentials);
			if (notCreated.length > 0) {
				notify.info(
					'The invoices have been generated successfully except for: ' +
						notCreated
							.map(b => b.code || b._id)
							.filter(Boolean)
							.join(', '),
					{ delay: 60000 }
				);
			} else {
				notify.success('Invoices issued correctly.');
				setReadyToBillList([]);
				window.location.reload();
			}
		} catch (err) {
			console.error(err);
			notify.error(`There was an issue generating the invoices: ${err.body.message}`);
			setAPIErrorMessage(err.body.message);
		}
		setCreatingInvoices(false);
	};

	const contextValues = {
		readyToBillList,
		loading,
		creatingInvoices,
		apiErrorMessage,
		fetchReadyToBillBookingsAtPage,
		generateTripCommissionInvoices,
	};

	return (
		<ReadyToBillContext.Provider value={contextValues}>{children}</ReadyToBillContext.Provider>
	);
};
