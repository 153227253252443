import React, { useState, useContext } from 'react';
import { Switch, Route } from 'react-router-dom';

import Input from '@exploreshare/everest/base/Input';

import './Guides.css';

import GuideContext from '../contexts/GuideContext';

import GuideList from './GuideList';
import GuideScreen from './GuideScreen';

const Guides = () => {
	const { loading } = useContext(GuideContext);
	const [query, setQuery] = useState('');

	return (
		<Switch>
			<Route path="/guides/:guideId">
				<GuideScreen />
			</Route>
			<Route path="/guides">
				<h1 className="main-title">Guides</h1>
				<table className="guides-table">
					<thead>
						<tr>
							<th style={{ width: '80px' }}></th>
							<th style={{ width: '70px' }}>ID</th>
							<th style={{ width: '190px' }}>First Name</th>
							<th style={{ width: '300px' }}>Last Name</th>
							<th>Email</th>
							<th>
								<Input
									handleChange={e => setQuery(e.target.value)}
									errorLabels={[]}
									helpLabels={[]}
									placeholder="Search"
									value={query}
									isValid={true}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						{loading ? (
							<tr>
								<td colSpan={6} style={{ textAlign: 'center' }}>
									Loading
								</td>
							</tr>
						) : (
							<GuideList query={query} />
						)}
					</tbody>
				</table>
			</Route>
		</Switch>
	);
};

export default Guides;
