import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './Customers.css';

import Input from '@exploreshare/everest/base/Input';

import CustomerList from './CustomerList';
import CustomerScreen from './CustomerScreen';

const Customers = () => {
	const [query, setQuery] = useState('');

	return (
		<Switch>
			<Route path="/customers/:customerId">
				<CustomerScreen />
			</Route>
			<Route path="/customers">
				<h1 className="main-title">Customers</h1>
				<table className="customers-table">
					<thead>
						<tr>
							<th style={{ width: '80px' }}></th>
							<th style={{ width: '190px' }}>First Name</th>
							<th style={{ width: '300px' }}>Last Name</th>
							<th>Email</th>
							<th>
								<Input
									handleChange={e => setQuery(e.target.value)}
									errorLabels={[]}
									helpLabels={[]}
									placeholder="Search"
									value={query}
									isValid={true}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						<CustomerList query={query} />
					</tbody>
				</table>
			</Route>
		</Switch>
	);
};

export default Customers;
