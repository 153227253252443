import React, { useState, useContext } from 'react';

import Text from '@exploreshare/everest/base/Text';
import Grid from '@exploreshare/everest/base/Grid';
import Button from '@exploreshare/everest/base/Button';

import DateInput from '../components/DateInput';

import AccountantContext from '../contexts/AccountantContext';

const AccountantCSV = () => {
	const { downloadSalesCSV, downloadClientsCSV } = useContext(AccountantContext);
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);
	const [downloadingSalesCSV, setDownloadingSalesCSV] = useState(false);
	const [downloadingClientsCSV, setDownloadingClientsCSV] = useState(false);

	const isFormCorrect = () =>
		from &&
		to &&
		!isNaN(new Date(from).getTime()) &&
		!isNaN(new Date(to).getTime()) &&
		new Date(from) < new Date(to);

	const downloadSales = async () => {
		if (!isFormCorrect()) return;
		setDownloadingSalesCSV(true);
		const ranges = {};
		if (from) ranges.from = from;
		if (to) ranges.to = to;
		await downloadSalesCSV(ranges);
		setDownloadingSalesCSV(false);
	};

	const downloadClients = async () => {
		if (!isFormCorrect()) return;
		setDownloadingClientsCSV(true);
		const ranges = {};
		if (from) ranges.from = from;
		if (to) ranges.to = to;
		await downloadClientsCSV(ranges);
		setDownloadingClientsCSV(false);
	};

	return (
		<>
			<h1 className="main-title">Accountant CSV</h1>
			<Text>Select a range of dates to download the accountant CSV:</Text>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<DateInput
						label="From"
						value={from}
						isValid={true}
						onChange={e => setFrom(e.target.value)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DateInput label="To" value={to} isValid={true} onChange={e => setTo(e.target.value)} />
				</Grid>
			</Grid>
			<Grid item>
				<Grid container spacing={2}>
					<Grid item>
						<Button disabled={!isFormCorrect() || downloadingSalesCSV} onClick={downloadSales}>
							Download Sales CSV
						</Button>
					</Grid>
					<Grid item>
						<Button disabled={!isFormCorrect() || downloadingClientsCSV} onClick={downloadClients}>
							Download Clients CSV
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default AccountantCSV;
