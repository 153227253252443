import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import esLocale from 'date-fns/locale/es';
import isAfter from 'date-fns/isAfter';
import isBefore from 'date-fns/isBefore';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import isValid from 'date-fns/isValid';

import EverestLink from '@exploreshare/everest/base/Link';

import Pager from '../components/Pager';

import InvoiceContext from '../contexts/InvoiceContext';

import formatMoney from '../util/formatMoney';

const ROWS_PER_PAGE = 20;

const escapeRegExp = s => s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');

const InvoiceList = ({ query, showInvoices, showCreditNotes, filters }) => {
	const { invoiceList: invoices } = useContext(InvoiceContext);
	const [page, setPage] = useState(0);
	const { invoiceType, invoiceOrCreditNote, from, to } = filters;

	const nextPage = nextPage => setPage(nextPage);
	const prevPage = prevPage => setPage(prevPage);

	useEffect(() => setPage(0), [query, invoiceType, invoiceOrCreditNote, from, to]);

	// Apply query
	const regex = new RegExp(escapeRegExp(query), 'i');
	const getQueryingFields = invoice =>
		[
			invoice.guide && [invoice.guide.first_name, invoice.guide.last_name],
			[invoice.to.legal_name],
			invoice.booking && [invoice.booking.code],
		]
			.filter(Boolean)
			.reduce((memo, v) => memo.concat(v), [invoice._id]);

	const fromDate = parse(from, 'yyyy-MM-dd', new Date());
	const untilDate = parse(to, 'yyyy-MM-dd', new Date());

	// Apply filters
	const filteredInvoices = invoices
		/// Query filter
		.filter(i => !query.trim() || getQueryingFields(i).reduce((m, v) => m || regex.test(v), false))
		/// Invoice type filter
		.filter(i => (!invoiceType || invoiceType === 'all' ? true : i.type.includes(invoiceType)))
		/// Credit note filter
		.filter(i => {
			if (invoiceOrCreditNote === 'all') return true;
			if (invoiceOrCreditNote === 'invoices') return !i.credit_note;
			return i.credit_note;
		})
		/// Date from filter
		.filter(i =>
			fromDate && isValid(fromDate) ? isAfter(parseISO(i.issue_date), startOfDay(fromDate)) : true
		)
		/// Date until filter
		.filter(i =>
			untilDate && isValid(untilDate) ? isBefore(parseISO(i.issue_date), endOfDay(untilDate)) : true
		);

	const invoiceListContent = (() => {
		if (!invoices.length) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						It appears there are no invoices
					</td>
				</tr>
			);
		}
		const invoiceList = filteredInvoices
			.reverse()
			.slice(page * ROWS_PER_PAGE, (page + 1) * ROWS_PER_PAGE);

		return invoiceList.map(invoice => (
			<tr key={invoice._id}>
				<td>
					{invoice.type
						.split('/')[1]
						.replace(/-/g, ' ')
						.replace(/^(.)/, v => v.toUpperCase())}
				</td>
				<td>{invoice._id}</td>
				<td>{format(parseISO(invoice.issue_date), 'dd-MM-yyyy', { locale: esLocale })}</td>
				{invoice.to._id ? (
					<td>
						<Link
							component={EverestLink}
							to={
								isNaN(Number(invoice.to._id))
									? `/customers/${invoice.to._id}`
									: `/guides/${invoice.to._id}`
							}>
							{invoice.to.legal_name}
						</Link>
					</td>
				) : (
					<td>{invoice.to.legal_name}</td>
				)}
				<td>{invoice.to.email}</td>
				{invoice.booking ? (
					<td>
						<Link component={EverestLink} to={`/bookings/${invoice.booking._id}`}>
							{invoice.booking.code}
						</Link>
					</td>
				) : (
					<td>-</td>
				)}
				<td className="text-right">
					{invoice.currency} {formatMoney(invoice.amount.full)}
				</td>
				<td>
					<Link component={EverestLink} to={`/invoices/${invoice._id}`}>
						View
					</Link>
				</td>
			</tr>
		));
	})();

	return (
		<>
			{invoiceListContent}
			<tr className="pager">
				<td colSpan={12}>
					<Pager
						onPrevPage={prevPage}
						onNextPage={nextPage}
						pagesCount={Math.ceil(filteredInvoices.length / ROWS_PER_PAGE)}
					/>
				</td>
			</tr>
		</>
	);
};

export default InvoiceList;
