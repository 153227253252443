import checkResponseOk from './checkResponseOk';

const { REACT_APP_LA2_API } = process.env;

export const fetchBookingByCode = async (code, { token }) => {
	const response = await fetch(`${REACT_APP_LA2_API}/api/v1/dashboard/bookings/code/${code}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchLA2Booking = async (id, { token }) => {
	const response = await fetch(`${REACT_APP_LA2_API}/api/v1/dashboard/bookings/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};
