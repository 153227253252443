import React, { createContext, useState, useContext } from 'react';

import Toast from '@exploreshare/everest/base/Toast';

import shortId from '../util/shortId';

const NotificationContext = createContext({
	notifications: [],
	notify: () => {},
});

export default NotificationContext;

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);

	const notify = ({ type, title, delay = 2000 }) => {
		const id = shortId();
		setNotifications(notifications.concat({ title, type, id }));
		setTimeout(() => removeNotification(id)(), delay);
	};

	const removeNotification = id => () => {
		setNotifications(notifications.filter(n => n.id !== id));
	};

	const providerValues = {
		notifications,
		notify,
	};

	return (
		<NotificationContext.Provider value={providerValues}>
			<div style={{ position: 'fixed', top: 0, width: '100vw', zIndex: 40000 }}>
				{notifications.map(n => (
					<Toast label={n.title} key={n.id} type={n.type} onClose={removeNotification(n.id)} />
				))}
			</div>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotifications = () => {
	const { notify } = useContext(NotificationContext);
	notify.error = (title, { delay } = {}) => notify({ title, delay });
	notify.warning = (title, { delay } = {}) => notify({ title, type: 'warning', delay });
	notify.info = (title, { delay } = {}) => notify({ title, type: 'info', delay });
	notify.success = (title, { delay } = {}) => notify({ title, type: 'correct', delay });
	return notify;
};
