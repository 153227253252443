import React from 'react';
import { Link } from 'react-router-dom';

import Text from '@exploreshare/everest/base/Text';
import EverestLink from '@exploreshare/everest/base/Link';

import './BookingsTable.css';
import Pager from '../Pager';
import formatMoney from '../../util/formatMoney';

// This component is intended to be used with bookings saved in Algolia.
// That means that the fields expected inside a booking may be different
// from the ones present in LA2.
const BookingsTable = ({ bookings, onPrevPage = () => {}, onNextPage = () => {} }) => {
	return (
		<table className="bookings-table">
			<thead>
				<tr>
					<th>Code</th>
					<th>Guide</th>
					<th>Customer</th>
					<th>Trip</th>
					<th className="header-right">Total price</th>
					<th className="header-right">Actions</th>
				</tr>
			</thead>
			<tbody>
				{bookings.length ? null : (
					<tr>
						<td colSpan={5} align="center">
							No bookings available
						</td>
					</tr>
				)}
				{bookings.map(b => (
					<tr key={b.objectID}>
						<td align="left">
							<Link component={EverestLink} to={`/bookings/${b.objectID}`}>
								{b.code}
							</Link>
						</td>
						<td align="left">
							<Text>
								{b.guide.first_name || b.guide.last_name
									? `${b.guide.first_name} ${b.guide.last_name}`
									: b.guide.email}
							</Text>
						</td>
						<td align="left">
							<Link component={EverestLink} to={`/customers/${b.lead._id}`}>
								{b.lead.first_name || b.lead.last_name
									? `${b.lead.first_name} ${b.lead.last_name} (${b.lead.email || ''})`
									: b.lead.email}
							</Link>
						</td>
						<td align="left">
							<Text>{b.trip.title}</Text>
						</td>
						<td align="right">
							<Text>
								{b.prices ? `${b.prices.currency} ${formatMoney(b.prices.totalPrice)}` : '-'}
							</Text>
						</td>
						<td align="right">
							<Link
								component={EverestLink}
								to={`/invoices/new/invoice?type=customer-fee&booking=${b.code}`}>
								New customer fee invoice
							</Link>
							<br />
							<Link
								component={EverestLink}
								to={`/invoices/new/invoice?type=trip-commission&booking=${b.code}`}>
								New trip commission invoice
							</Link>
							<br />
							<Link
								component={EverestLink}
								to={`/invoices/new/invoice?type=admin-fee&booking=${b.code}`}>
								New admin fee invoice
							</Link>
						</td>
					</tr>
				))}
				<tr className="pager">
					<td colSpan="12">
						<Pager onPrevPage={onPrevPage} onNextPage={onNextPage} pagesCount={1} />
					</td>
				</tr>
			</tbody>
		</table>
	);
};

export default BookingsTable;
