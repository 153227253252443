import React, { useContext } from 'react';
import { Switch, Route, Link, useLocation } from 'react-router-dom';
import './Dashboard.css';

import Navbar from './Navbar';

import AuthContext from '../contexts/AuthContext';
import { GuideProvider } from '../contexts/GuideContext';
import { CustomerProvider } from '../contexts/CustomerContext';
import { InvoiceProvider } from '../contexts/InvoiceContext';
import { BookingProvider } from '../contexts/BookingContext';
import { ReadyToBillProvider } from '../contexts/ReadyToBillContext';
import { AccountantProvider } from '../contexts/AccountantContext';

import Customers from '../Customers';
import Guides from '../Guides';
import Invoices from '../Invoices';
import Bookings from '../Bookings';
import ReadyToBill from '../ReadyToBill';
import AccountantCSV from '../AccountantCSV';

const sidebarItems = [
	{ title: 'Customers', slug: 'customers' },
	{ title: 'Guides', slug: 'guides' },
	{ title: 'Invoices', slug: 'invoices' },
	{ title: 'Bookings', slug: 'bookings' },
	{ title: 'Ready to Bill', slug: 'ready-to-bill' },
	{ title: 'Accountant CSV', slug: 'accountant-csv' },
];

const Dashboard = () => {
	const { credentials } = useContext(AuthContext);
	const location = useLocation();
	const timeOfDay = (() => {
		const d = new Date();
		if (d.getHours() < 12) return 'morning';
		if (d.getHours() < 19) return 'afternoon';
		return 'night';
	})();
	const [slug] = location.pathname.split('/').slice(1);
	const menu = sidebarItems.find(v => v.slug === slug);
	const mainTitle = (() => {
		if (menu) return null;
		return (
			<>
				<h1 className="welcome-h1">
					Good {timeOfDay} {credentials.profile.first_name}
				</h1>
				<span className="muted text-center">Click a menu on the sidebar to get started</span>
			</>
		);
	})();

	return (
		<div className="grid-container">
			<div className="Header">
				<Navbar />
			</div>
			<div className="Main">
				{mainTitle}
				<Switch>
					<Route path="/customers">
						<CustomerProvider>
							<BookingProvider>
								<Customers />
							</BookingProvider>
						</CustomerProvider>
					</Route>
					<Route path="/guides">
						<GuideProvider>
							<BookingProvider>
								<Guides />
							</BookingProvider>
						</GuideProvider>
					</Route>
					<Route path="/invoices">
						<InvoiceProvider>
							<GuideProvider>
								<BookingProvider>
									<Invoices />
								</BookingProvider>
							</GuideProvider>
						</InvoiceProvider>
					</Route>
					<Route path="/bookings">
						<BookingProvider>
							<Bookings />
						</BookingProvider>
					</Route>
					<Route path="/ready-to-bill">
						<InvoiceProvider>
							<ReadyToBillProvider>
								<ReadyToBill />
							</ReadyToBillProvider>
						</InvoiceProvider>
					</Route>
					<Route path="/accountant-csv">
						<AccountantProvider>
							<AccountantCSV />
						</AccountantProvider>
					</Route>
					<Route path="/" exact></Route>
				</Switch>
			</div>
			<div className="Sidebar">
				{sidebarItems.map(({ title, slug }) => (
					<Link
						to={`/${slug}`}
						key={slug}
						className={`menu-item ${menu && menu.slug === slug ? 'selected' : ''}`}>
						{title}
					</Link>
				))}
			</div>
		</div>
	);
};

export default Dashboard;
