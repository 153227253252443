import React, { createContext, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AuthContext from './AuthContext';
import { useNotifications } from './NotificationContext';

import * as InvoiceService from '../data/invoices';
import useDebounce from '../util/useDebounce';
import downloadFile from '../util/downloadFile';
import { format } from 'date-fns';

const InvoiceContext = createContext({
	invoiceList: [],
	loading: true,
	htmlInvoicePreview: null,
	apiErrorMessage: '',
	createInvoice: async () => {},
	createCreditNote: () => {},
	searchInvoice: () => {},
	searchCreditNotesFromInvoice: () => {},
	fetchInvoices: () => {},
	selectInvoice: () => {},
	deselectInvoice: () => {},
	getInvoicePreview: () => {},
	clearInvoicePreview: () => {},
	downloadCSV: () => {},
});

export default InvoiceContext;

export const InvoiceProvider = ({ children }) => {
	const { credentials } = useContext(AuthContext);
	const history = useHistory();
	const notify = useNotifications();
	const [invoiceList, setInvoiceList] = useState([]);
	const [loading, setLoading] = useState(true);
	const [creatingInvoice, setCreatingInvoice] = useState(false);
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [loadingInvoiceDetails, setLoadingInvoiceDetails] = useState(true);
	const [invoicePreviewBody, setInvoicePreviewBody] = useState();
	const [htmlInvoicePreview, setHtmlInvoicePreview] = useState();
	const [apiErrorMessage, setAPIErrorMessage] = useState('');

	useDebounce(
		async () => {
			if (!invoicePreviewBody) return;
			setAPIErrorMessage('');
			try {
				const invoicePreview = await InvoiceService.getInvoicePreview(
					invoicePreviewBody,
					credentials
				);
				setHtmlInvoicePreview(invoicePreview);
			} catch (e) {
				console.error(e);
				notify.error(`There was an issue previewing the invoice: ${e.body.message}`);
				setAPIErrorMessage(e.body.message);
			}
		},
		300,
		[invoicePreviewBody]
	);

	const getInvoicePreview = body => setInvoicePreviewBody(body);

	const clearInvoicePreview = () => setHtmlInvoicePreview(null);

	const fetchInvoices = async () => {
		setLoading(true);
		const invoiceList = await InvoiceService.fetchInvoiceList(credentials);
		setInvoiceList(invoiceList);
		setLoading(false);
	};

	useEffect(() => {
		fetchInvoices();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [credentials]);

	const createInvoice = async body => {
		notify.info('Creating invoice');
		setCreatingInvoice(true);
		setAPIErrorMessage('');
		try {
			await InvoiceService.generateInvoice(body, credentials);
			notify.success('Invoice issued correctly.');
		} catch (err) {
			console.error(err);
			notify.error(`There was an issue generating the invoice: ${err.body.message}`);
			setAPIErrorMessage(err.body.message);
		}
		setCreatingInvoice(false);
		fetchInvoices();
	};

	const createCreditNote = async body => {
		notify.info('Creating credit note');
		setCreatingInvoice(true);
		setAPIErrorMessage('');
		try {
			await InvoiceService.generateCreditNote(body, credentials);
			notify.success('Credit note issued correctly.');
		} catch (err) {
			console.error(err);
			notify.error(`There was an issue generating the credit note: ${err.body.message}`);
			setAPIErrorMessage(err.body.message);
		}
		setCreatingInvoice(false);
		fetchInvoices();
	};

	const searchInvoice = async id => {
		try {
			const invoice = await InvoiceService.fetchInvoice(id, credentials);
			return invoice;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const searchCreditNotesFromInvoice = async invoiceId => {
		try {
			const invoice = await InvoiceService.fetchCreditNotesFromInvoice(invoiceId, credentials);
			return invoice;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const selectInvoice = async invoiceId => {
		if (!invoiceId) return setSelectedInvoice(null);
		setLoadingInvoiceDetails(true);
		const [data, creditNotes] = await Promise.all([
			InvoiceService.fetchInvoice(invoiceId, credentials),
			invoiceId.match(/^F .*/)
				? InvoiceService.fetchCreditNotesFromInvoice(invoiceId, credentials)
				: [],
		]);
		setSelectedInvoice({ ...data, dirty: {}, original: data, creditNotes: creditNotes || [] });
		setLoadingInvoiceDetails(false);
	};

	const deselectInvoice = () => {
		setSelectedInvoice(null);
		setLoadingInvoiceDetails(true);
		history.push('/invoices');
	};

	const downloadCSV = async ({ from, to }) => {
		const csv = await InvoiceService.downloadCSV({ from, to }, credentials);
		downloadFile({
			content: csv,
			filename: `${format(new Date(from || null), 'yyyyMMdd')}-${format(
				new Date(to || null),
				'yyyyMMdd'
			)}.csv`,
		});
	};

	const contextValues = {
		invoiceList,
		loading,
		createInvoice,
		createCreditNote,
		searchInvoice,
		searchCreditNotesFromInvoice,
		loadingInvoiceDetails,
		selectedInvoice,
		selectInvoice,
		deselectInvoice,
		creatingInvoice,
		fetchInvoices,
		getInvoicePreview,
		clearInvoicePreview,
		htmlInvoicePreview,
		downloadCSV,
		apiErrorMessage,
	};

	return <InvoiceContext.Provider value={contextValues}>{children}</InvoiceContext.Provider>;
};
