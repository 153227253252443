import React, { useState, useEffect } from 'react';

import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';
import Select from '@exploreshare/everest/base/Select';

import invoiceTypes from '../../util/invoiceTypes';
import DateInput from '../../components/DateInput';

const Filters = ({ onChange = () => {} }) => {
	const [invoiceType, setInvoiceType] = useState('all');
	const [invoiceOrCreditNote, setInvoiceOrCreditNote] = useState('all');
	const [from, setFrom] = useState(null);
	const [to, setTo] = useState(null);

	useEffect(() => {
		onChange({
			invoiceType,
			invoiceOrCreditNote,
			from,
			to,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceType, invoiceOrCreditNote, from, to]);

	return (
		<>
			<Text size="lg" lineHeight="xl">
				Filters
			</Text>
			<Grid container spacing={2}>
				<Grid item xs={3}>
					<Select
						fullWidth
						options={[{ value: 'all', label: 'All' }, ...invoiceTypes]}
						label="Invoice type"
						placeholder="Select a value..."
						handleChange={e => setInvoiceType(e.target.value)}
						errorLabels={['Please select an option']}
						value={invoiceType}
						isValid={Boolean(invoiceType)}
					/>
				</Grid>
				<Grid item xs={3}>
					<Select
						fullWidth
						options={[
							{ value: 'all', label: 'All' },
							{ value: 'invoices', label: 'Only invoices' },
							{ value: 'credit-notes', label: 'Only credit notes' },
						]}
						label="Invoice/Credit Note"
						placeholder="Select a value..."
						handleChange={e => setInvoiceOrCreditNote(e.target.value)}
						errorLabels={['Please select an option']}
						value={invoiceOrCreditNote}
						isValid={Boolean(invoiceOrCreditNote)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DateInput
						label="From"
						value={from}
						isValid={true}
						onChange={e => setFrom(e.target.value)}
					/>
				</Grid>
				<Grid item xs={3}>
					<DateInput label="To" value={to} isValid={true} onChange={e => setTo(e.target.value)} />
				</Grid>
			</Grid>
		</>
	);
};

export default Filters;
