import React, { useState, useEffect, useRef, useContext } from 'react';
import './GuideSearch.css';
import GuideContext from '../../contexts/GuideContext';
import Input from '@exploreshare/everest/base/Input';

const escapeRegExp = s => s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');

const GuideSearch = ({ onSelected }) => {
	const { guideList } = useContext(GuideContext);
	const [guideSearchText, setGuideSearchText] = useState('');
	const [foundGuides, setFoundGuides] = useState([]);
	const [showResults, setShowResults] = useState(false);

	const componentRef = useRef();

	const handleClickOutside = e => {
		if (componentRef && componentRef.current && !componentRef.current.contains(e.target)) {
			setShowResults(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const searchGuide = async searchText => {
		setGuideSearchText(searchText);
		const regex = new RegExp(escapeRegExp(searchText), 'i');
		const matchingGuides = (guideList || [])
			.filter(g => {
				if (!searchText.trim()) return true;
				return [g.first_name, g.last_name, g.email, g._id.toString()].reduce(
					(m, v) => m || regex.test(v),
					false
				);
			})
			.slice(0, 10);
		setFoundGuides(matchingGuides);
		setShowResults(true);
	};

	const selectGuide = guide => () => {
		if (onSelected) onSelected(guide);
		setShowResults(false);
		setGuideSearchText((guide.first_name || '') + ' ' + (guide.last_name || ''));
	};

	return (
		<>
			<Input
				label="Guide"
				placeholder="Guide name"
				isValid={true}
				value={guideSearchText}
				handleChange={event => searchGuide(event.target.value)}
			/>
			<div
				className="guide-search-results"
				ref={componentRef}
				style={{ display: showResults && foundGuides.length > 0 ? 'inherit' : 'none' }}>
				{foundGuides.map(guide => (
					<p onClick={selectGuide(guide)} className="guide-search-single-results" key={guide._id}>
						{guide.first_name || ''} {guide.last_name || ''}
					</p>
				))}
			</div>
		</>
	);
};

export default GuideSearch;
