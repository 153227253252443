import checkResponseOk from './checkResponseOk';
import filterEmptyProps from '../util/filterEmptyProps';

const { REACT_APP_BILLING_API } = process.env;

export const fetchInvoiceList = async ({ token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json',
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const downloadCSV = async ({ from, to }, { token }) => {
	const url = new URL(`${REACT_APP_BILLING_API}/v1/invoices`);
	const params = {};
	if (from) params.from = new Date(from).getTime();
	if (to) params.to = new Date(to).getTime();
	url.search = new URLSearchParams(params).toString();

	const response = await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: 'text/csv',
		},
	});
	await checkResponseOk(response);
	return await response.text();
};

export const fetchInvoice = async (id, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchInvoiceByTitle = async (title, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices/title/${title}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchInvoicesFromBooking = async (bookingId, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices?booking=${bookingId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json',
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchCreditNotesFromInvoice = async (invoiceId, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices?parent=${invoiceId}`, {
		headers: {
			Authorization: `Bearer ${token}`,
			Accept: 'application/json',
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const generateInvoice = async (body, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const generateTripCommissionInvoices = async (body, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices/trip-commission`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const getInvoicePreview = async (params, { token }) => {
	const url = new URL(`${REACT_APP_BILLING_API}/v1/invoices/preview`);
	url.search = new URLSearchParams(filterEmptyProps(params)).toString();

	const response = await fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.text();
};

export const generateCreditNote = async (body, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices/`, {
		method: 'POST',
		body: JSON.stringify(body),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};
