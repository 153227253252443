import { format } from 'date-fns';

export default ({
	content = '',
	type = 'text/plain',
	filename = `billing-${format(new Date(), 'yyyyMMdd')}.txt`,
}) => {
	const element = document.createElement('a');
	const file = new Blob([content], { type });
	element.href = URL.createObjectURL(file);
	element.download = filename;
	document.body.appendChild(element); // Required for this to work in FireFox
	element.click();
};
