import React, { createContext, useState, useEffect } from 'react';
import { fetchProfile } from '../data/auth';

const AuthContext = createContext({
	credentials: null,
	logIn: () => {},
	logOut: () => {},
});

export default AuthContext;

const LOCAL_STORAGE_TOKEN_KEY = 'es:token';

export const AuthProvider = ({ children }) => {
	const [loading, setLoading] = useState(false);
	const [credentials, setCredentials] = useState(null);

	useEffect(() => {
		const load = async () => {
			const token = localStorage.getItem(LOCAL_STORAGE_TOKEN_KEY);
			if (!token) return;
			setLoading(true);
			try {
				const profile = await fetchProfile({ token });
				setCredentials({ profile, token });
			} catch (e) {
				console.error(e);
			}
			setLoading(false);
		};
		load();
	}, []);

	const logIn = credentials => {
		localStorage.setItem(LOCAL_STORAGE_TOKEN_KEY, credentials.token);
		setCredentials(credentials);
	};
	const logOut = () => {
		localStorage.removeItem(LOCAL_STORAGE_TOKEN_KEY);
		setCredentials(null);
	};

	const content = (() => {
		if (!loading) return children;
		return <h1>Loading dashboard...</h1>;
	})();

	return (
		<AuthContext.Provider value={{ credentials, logIn, logOut }}>{content}</AuthContext.Provider>
	);
};
