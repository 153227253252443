/**
 * Formats numbers to money
 * @param  {Number} n The number to format
 * @param  {Number} c Precision
 * @param  {String} d Decimal separator
 * @param  {String} t Thousands separator
 * @return {String}   The formated number
 */
module.exports = (_n, _c = 2, d = ',', t = '.') => {
	const c = (() => {
		if (isNaN(Math.abs(_c))) return 2;
		return Math.abs(_c);
	})();
	const n = Math.abs(Number(_n) || 0).toFixed(c);
	const i = String(parseInt(n, 10));
	const j = i.length > 3 ? i.length % 3 : 0;
	return `${_n < 0 ? '-' : ''}${j ? i.substr(0, j) + t : ''}${i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t)}${c ? d + Math.abs(n - i).toFixed(c).slice(2) : ''}`;
};
