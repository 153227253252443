import checkResponseOk from './checkResponseOk';

const { REACT_APP_BILLING_API } = process.env;

export const downloadSalesCSV = async ({ from, to }, { token }) => {
	const url = new URL(`${REACT_APP_BILLING_API}/v1/accountant/sales`);
	const params = {};
	if (from) params.from = new Date(from).getTime();
	if (to) params.to = new Date(to).getTime();
	url.search = new URLSearchParams(params).toString();

	const response = await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	checkResponseOk(response);
	return await response.text();
};

export const downloadClientsCSV = async ({ from, to }, { token }) => {
	const url = new URL(`${REACT_APP_BILLING_API}/v1/accountant/clients`);
	const params = {};
	if (from) params.from = new Date(from).getTime();
	if (to) params.to = new Date(to).getTime();
	url.search = new URLSearchParams(params).toString();

	const response = await fetch(url, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	checkResponseOk(response);
	return await response.text();
};
