import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './Bookings.css';

import Input from '@exploreshare/everest/base/Input';

import BookingList from './BookingList';
import BookingScreen from './BookingScreen';

const Bookings = () => {
	const [query, setQuery] = useState('');

	return (
		<Switch>
			<Route path="/bookings/:bookingId">
				<BookingScreen />
			</Route>
			<Route path="/bookings">
				<h1 className="main-title">Bookings</h1>
				<table className="bookings-table">
					<thead>
						<tr>
							<th style={{ width: '120px' }}>Code</th>
							<th style={{ width: '400px' }}>Trip</th>
							<th style={{ width: '300px' }}>Guide</th>
							<th>Lead</th>
							<th>
								<Input
									handleChange={e => setQuery(e.target.value)}
									errorLabels={[]}
									helpLabels={[]}
									placeholder="Search"
									value={query}
									isValid={true}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						<BookingList query={query} />
					</tbody>
				</table>
			</Route>
		</Switch>
	);
};

export default Bookings;
