import checkResponseOk from './checkResponseOk';

const { REACT_APP_LA2_API } = process.env;

export const authenticate = async ({ email, password }) => {
	const response = await fetch(`${REACT_APP_LA2_API}/api/v1/dashboard/auth/login`, {
		method: 'post',
		body: JSON.stringify({
			email,
			password,
		}),
		headers: {
			'Content-Type': 'application/json',
		},
	});
	await checkResponseOk(response);
	const { token } = await response.json();
	return token;
};

export const fetchProfile = async ({ token }) => {
	const response = await fetch(`${REACT_APP_LA2_API}/api/v1/dashboard/auth/profile`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};
