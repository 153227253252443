import React, { useState, useContext } from 'react';
import './AuthScreen.css';

import Container from '@exploreshare/everest/base/Container';
import Input from '@exploreshare/everest/base/Input';
import Header from '@exploreshare/everest/base/Header';
import Button from '@exploreshare/everest/base/Button';

import Logo from '@exploreshare/everest/icons/Logo';

import AuthContext from '../contexts/AuthContext';

import { authenticate, fetchProfile } from '../data/auth';
import { useNotifications } from '../contexts/NotificationContext';

const AuthScreen = () => {
	const { logIn } = useContext(AuthContext);
	const notify = useNotifications();
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const logInHandler = async () => {
		try {
			const token = await authenticate({ email, password });
			const profile = await fetchProfile({ token });
			logIn({ profile, token });
		} catch (err) {
			console.error(err);
			notify.error('Ingrese un usuario y contraseña válidos.');
		}
	};
	return (
		<Container className="auth-container">
			<Header className="auth-title">
				<Logo /> Explore Share - Billing
			</Header>
			<Input
				isValid
				placeholder="Email"
				value={email}
				handleChange={e => setEmail(e.target.value)}
			/>
			<Input
				isValid
				placeholder="Password"
				type="password"
				value={password}
				handleChange={e => setPassword(e.target.value)}
			/>
			<Button disabled={!email || !password} onClick={logInHandler}>
				Log In
			</Button>
		</Container>
	);
};

export default AuthScreen;
