import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchCountries } from '../data/countries';

const CountriesContext = createContext({
	countries: [],
});

export default CountriesContext;

export const CountryProvider = ({ children }) => {
	const [countries, setCountries] = useState([]);

	useEffect(() => {
		const load = async () => {
			const countries = await fetchCountries();
			setCountries(countries);
		};
		load();
	}, []);

	return <CountriesContext.Provider value={{ countries }}>{children}</CountriesContext.Provider>;
};

export const useCountries = () => {
	const { countries } = useContext(CountriesContext);
	return countries;
};
