import React, { useContext, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import esLocale from 'date-fns/locale/es';

import InvoiceContext from '../contexts/InvoiceContext';
import Button from '@exploreshare/everest/base/Button';
import EverestLink from '@exploreshare/everest/base/Link';
import Text from '@exploreshare/everest/base/Text';
import Header from '@exploreshare/everest/base/Header';

import formatMoney from '../util/formatMoney';

const InvoiceScreen = () => {
	const { invoiceId } = useParams();
	const history = useHistory();
	const { selectedInvoice: invoice, loadingInvoiceDetails, selectInvoice, loading } = useContext(
		InvoiceContext
	);

	useEffect(() => {
		selectInvoice(invoiceId);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [invoiceId]);

	if (loadingInvoiceDetails || loading) return <h1>Loading</h1>;

	const title = (() => {
		const [type, scope] = invoice.type.split('/');
		const t = `${scope.replace(/-/g, ' ')} ${type === 'cn' ? 'credit note' : 'invoice'} ${
			invoice._id
		}`;
		return `${t[0].toUpperCase()}${t.slice(1)}`;
	})();

	return (
		<>
			<table className="guide-screen__title-table">
				<tbody>
					<tr>
						<td>
							<Header>{title}</Header>
						</td>
						<td style={{ verticalAlign: 'top', textAlign: 'right' }}>
							<Link
								onClick={e => {
									e.preventDefault();
									history.goBack();
								}}
								component={EverestLink}>
								&larr; Go back
							</Link>
						</td>
					</tr>
				</tbody>
			</table>

			<div className="invoice-screen__info-section">
				<div className="invoice-screen__detail-section">
					<Text size="lg">Issue date</Text>
					<Text>{format(parseISO(invoice.issue_date), 'dd-MM-yyyy', { locale: esLocale })}</Text>
					<br />
					<Text size="lg">To</Text>
					<Text>{invoice.to.legal_name}</Text>
					<Text>{invoice.to.email}</Text>
					<Text>{invoice.to.phone}</Text>
					<br />
					<Text size="lg">Address</Text>
					<Text>
						{invoice.address.address} {invoice.address.address_number}
					</Text>
					<Text>
						{invoice.address.city} ({invoice.address.zip_code}), {invoice.address.country}
					</Text>
					{invoice.booking && (
						<>
							<br />
							<Text size="lg">Booking</Text>
							<Text>{invoice.booking.code}</Text>
							<Text>
								{format(parseISO(invoice.booking.departure_date), 'dd-MM-yyyy', {
									locale: esLocale,
								})}
							</Text>
						</>
					)}
					{invoice.trip && invoice.trip.title && (
						<>
							<br />
							<Text size="lg">Trip</Text>
							<Text>{invoice.trip.title}</Text>
						</>
					)}
					{invoice.guide && (
						<>
							<br />
							<Text size="lg">Guide</Text>
							<Link to={`/guides/${invoice.guide._id}`} component={EverestLink}>
								{invoice.guide.first_name} {invoice.guide.last_name}
							</Link>
							<br />
						</>
					)}
					{invoice.creditNotes.length > 0 ? (
						<>
							<br />
							<Text size="lg">Credit notes</Text>
							{invoice.creditNotes.map(cn => (
								<div key={cn._id}>
									<Link to={`/invoices/${cn._id}`} component={EverestLink}>
										{cn._id}
									</Link>
									<Text inline>
										&nbsp;({cn.currency} {formatMoney(cn.amount.full)})
									</Text>
									<br />
								</div>
							))}
						</>
					) : null}
					{invoice.credit_note ? (
						<>
							<br />
							<Text size="lg">Invoice reference</Text>
							<Link to={`/invoices/${invoice.parent}`} component={EverestLink}>
								{invoice.parent}
							</Link>
							<br />
						</>
					) : null}
					{invoice.credit_note ? null : (
						<>
							<br />
							<Text size="lg">Actions</Text>
							<Button
								secondary
								onClick={() => history.push(`/invoices/new/credit-note?invoice=${invoice._id}`)}>
								Issue credit note
							</Button>
						</>
					)}
				</div>
				<div className="invoice-screen__preview-section">
					<Text size="lg">Invoice Preview</Text>
					<iframe title={invoice._id} src={invoice.url}></iframe>
				</div>
			</div>
		</>
	);
};

export default InvoiceScreen;
