import checkResponseOk from './checkResponseOk';

const { REACT_APP_LA2_API, REACT_APP_BILLING_API } = process.env;

export const fetchGuideList = async ({ token }) => {
	const response = await fetch(`${REACT_APP_LA2_API}/api/v1/dashboard/guides/min-info`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchGuide = async (id, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/guides/${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchInvoiceFromGuide = async (id, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices?guide=${id}`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const updateGuide = async (id, delta, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/guides/${id}`, {
		method: 'PATCH',
		body: JSON.stringify(delta),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};
