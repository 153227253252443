/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import './ReadyToBill.css';

import Input from '@exploreshare/everest/base/Input';
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';
import CheckBox from '@exploreshare/everest/base/CheckBox';
import Button from '@exploreshare/everest/base/Button';

import ReadyToBillList from './ReadyToBillList';
import DateInput from '../components/DateInput';
import range from 'lodash.range';

import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import format from 'date-fns/format';
import ReadyToBillContext from '../contexts/ReadyToBillContext';

const ReadyToBill = () => {
	const {
		readyToBillList: bookings,
		creatingInvoices,
		generateTripCommissionInvoices,
	} = useContext(ReadyToBillContext);
	const [query, setQuery] = useState('');
	const [from, setFrom] = useState(format(startOfMonth(new Date()), 'yyyy-MM-dd'));
	const [to, setTo] = useState(format(endOfMonth(new Date()), 'yyyy-MM-dd'));
	const [selected, setSelected] = useState([]);

	const toggleSelected = booking => checked => {
		if (checked) setSelected([...selected, booking]);
		else setSelected(selected.filter(b => b._id !== booking._id));
	};

	const toggleAll = checked => {
		if (checked) setSelected(bookings.map(booking => booking));
		else setSelected([]);
	};

	const createInvoices = () => {
		generateTripCommissionInvoices({ bookings: selected.map(b => b._id) });
	};

	return (
		<Switch>
			<Route path="/ready-to-bill/:bookingId">{/* <BookingScreen /> */}</Route>
			<Route path="/ready-to-bill">
				<Text size={25} lineHeight="xl">
					Ready to bill
				</Text>
				<Text>These are all the bookings that are pending a Trip Commission invoice.</Text>
				<Text size="lg" lineHeight="xl">
					Filters
				</Text>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<DateInput
							label="From"
							value={from}
							isValid={true}
							onChange={e => setFrom(e.target.value)}
						/>
					</Grid>
					<Grid item xs={3}>
						<DateInput label="To" value={to} isValid={true} onChange={e => setTo(e.target.value)} />
					</Grid>
				</Grid>
				<Button disabled={creatingInvoices || selected.length === 0} onClick={createInvoices}>
					{creatingInvoices
						? 'Creating invoices...'
						: `Create selected trip commission invoices (${selected.length})`}
				</Button>
				<Text size={10} lineHeight="sm">
					{selected.map(b => b.code).join(', ')}
				</Text>
				<table className="ready-to-bill-table">
					<thead>
						<tr>
							<th style={{ width: '90px', padding: '5px 10px' }}>
								<Grid container style={{ marginTop: '10px' }}>
									<Grid item>
										<CheckBox
											selected={Boolean(selected.length === bookings.length) && selected.length > 0}
											onClick={toggleAll}
										/>
									</Grid>
									<Grid item>({selected.length})</Grid>
								</Grid>
							</th>
							<th style={{ width: '120px' }}>Code</th>
							<th style={{ width: '200px' }}>Guide</th>
							<th style={{ width: '150px' }}>Total booking price</th>
							<th style={{ width: '150px' }}>Invoice amount</th>
							<th style={{ width: '120px' }}>Booking commission</th>
							<th style={{ width: '120px' }}>Paid Date</th>
							<th>
								<Input
									handleChange={e => setQuery(e.target.value)}
									errorLabels={[]}
									helpLabels={[]}
									placeholder="Search"
									value={query}
								/>
							</th>
						</tr>
					</thead>
					<tbody>
						<ReadyToBillList
							query={query}
							from={from}
							to={to}
							selected={selected}
							toggleSelected={toggleSelected}
							toggleAll={toggleAll}
						/>
					</tbody>
				</table>
			</Route>
		</Switch>
	);
};

export default ReadyToBill;
