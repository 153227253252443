import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './Customers.css';

import Image from '@exploreshare/everest/base/Image';

import Pager from '../components/Pager';
import CustomerContext from '../contexts/CustomerContext';

const convertAlgoliaLeadModel = lead => {
	const newLead = {
		_id: lead.objectID,
		email: lead.email,
		first_name: lead.first_name,
		last_name: lead.last_name,
		created: lead.created,
		avatar: lead.avatar,
		birthdate: lead.birthdate,
		phone: lead.phone,
		facebook: lead.facebook,
		instagram: lead.instagram,
	};
	if (lead.country) {
		newLead.country = {
			_id: Number(lead.country._id),
			title: lead.country.title,
		};
	}
	return newLead;
};

const CustomerList = ({ query }) => {
	const history = useHistory();
	const { customerList: customers, fetchCustomersAtPage, loading } = useContext(CustomerContext);
	const [page, setPage] = React.useState(0);
	const resultPerPage = 10;
	const search = [
		{
			$match: {
				$or: [
					{
						first_name: {
							$regex: query,
							$options: 'i',
						},
					},
					{
						last_name: {
							$regex: query,
							$options: 'i',
						},
					},
					{
						email: {
							$regex: query,
							$options: 'i',
						},
					},
				],
			},
		},
		{
			$sort: {
				last_updated: -1,
			},
		},

		{ $skip: resultPerPage * page },
		{ $limit: resultPerPage },
	];

	useEffect(() => {
		fetchCustomersAtPage(search);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, page]);

	const nextPage = nextPage => {
		//fetchCustomersAtPage(search, nextPage);
		setPage(prev => prev + 1);
	};

	const prevPage = prevPage => {
		//fetchCustomersAtPage(search, prevPage);
		setPage(prev => prev - 1);
	};

	const customerListContent = (() => {
		if (loading) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						Loading
					</td>
				</tr>
			);
		}
		if (!customers.length) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						It appears there are no customers
					</td>
				</tr>
			);
		}
		return customers.map(customer => (
			<tr key={customer._id} onClick={() => history.push(`/customers/${customer._id}`)}>
				<td>
					<Image
						alt={`${customer.first_name} ${customer.last_name}}`}
						className="avatar-image"
						rounded
						src={customer.avatar || 'https://unsplash.it/80/80'}
					/>
				</td>
				<td>{customer.first_name}</td>
				<td>{customer.last_name}</td>
				<td colSpan={2}>{customer.email}</td>
			</tr>
		));
	})();

	return (
		<>
			{customerListContent}
			<tr className="pager">
				<td colSpan={12}>
					<Pager onPrevPage={prevPage} onNextPage={nextPage} />
				</td>
			</tr>
		</>
	);
};

export default CustomerList;
