import React, { useState, useEffect, useRef } from 'react';
import algoliasearch from 'algoliasearch/lite';
import './TripSearch.css';
import Input from '@exploreshare/everest/base/Input';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY } = process.env;

const TripSearch = ({ onSelected }) => {
	const [tripSearchText, setTripSearchText] = useState('');
	const [algoliaFoundTrips, setAlgoliaFoundTrips] = useState([]);
	const [showResults, setShowResults] = useState(false);

	const componentRef = useRef();

	const handleClickOutside = e => {
		if (componentRef && componentRef.current && !componentRef.current.contains(e.target)) {
			setShowResults(false);
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const searchTrip = async searchText => {
		setTripSearchText(searchText);
		const client = algoliasearch(REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY);
		const index = client.initIndex('es_searchable_posts_dato');
		const tripList = await index
			.search(searchText, {
				hitsPerPage: 20,
			})
			.then(({ hits }) => hits);
		setAlgoliaFoundTrips(tripList);
		setShowResults(true);
	};

	const selectTrip = trip => () => {
		if (onSelected) onSelected(trip);
		setShowResults(false);
		setTripSearchText(trip.post_title);
	};

	return (
		<>
			<Input
				label="Trip"
				placeholder="Trip title"
				isValid={true}
				value={tripSearchText}
				handleChange={event => searchTrip(event.target.value)}
			/>
			<div
				className="trip-search-results"
				ref={componentRef}
				style={{ display: showResults && algoliaFoundTrips.length > 0 ? 'inherit' : 'none' }}>
				{algoliaFoundTrips.map(trip => (
					<p onClick={selectTrip(trip)} className="trip-search-single-results" key={trip.post_id}>
						{trip.post_title}
					</p>
				))}
			</div>
		</>
	);
};

export default TripSearch;
