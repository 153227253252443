import React from 'react';
import './PricingSummary.css';
import SummaryItem from './SummaryItem';
import * as Prices from '../../util/bookingPrices';
import formatMoney from '../../util/formatMoney';

const PricingSummary = ({ booking }) => {
	const { people_count, price_per_person, currency } = booking;

	const baseBookingPrice = Prices.getBaseBookingPrice(booking);
	const creditCardFee = Prices.getCreditCardFee(booking);
	const serviceFee = Prices.getCustomerFee(booking);
	const totalBookingPrice = Prices.getTotalBookingPrice(booking);

	return (
		<div className="prices-container">
			<SummaryItem title="Travellers" content={people_count} />
			<SummaryItem
				title="Price per person"
				content={`${currency} ${formatMoney(price_per_person)}`}
			/>
			<hr className="prices-table-separator" />
			<SummaryItem title="Subtotal" content={`${currency} ${formatMoney(baseBookingPrice)}`} />
			<SummaryItem
				title="+ Credit card fee"
				content={`${currency} ${formatMoney(creditCardFee)}`}
			/>
			<SummaryItem title="+ Service fee" content={`${currency} ${formatMoney(serviceFee)}`} />
			<hr className="prices-table-separator" />
			<SummaryItem title="Total" content={`${currency} ${formatMoney(totalBookingPrice)}`} bold />
		</div>
	);
};

export default PricingSummary;
