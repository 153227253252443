import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Input from '@exploreshare/everest/base/Input';
import Button from '@exploreshare/everest/base/Button';
import Select from '@exploreshare/everest/base/Select';
import Text from '@exploreshare/everest/base/Text';
import CheckBox from '@exploreshare/everest/base/CheckBox';
import Grid from '@exploreshare/everest/base/Grid';
import PhoneInput from '@exploreshare/everest/base/PhoneInput';

import NumericInput from '../../components/NumericInput';

import InvoiceContext from '../../contexts/InvoiceContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { useCountries } from '../../contexts/CountriesContext';

import currencies from '../../util/currencies';
import invoicingCategories from '../../util/invoicingCategories';
import isEmailValid from '../../util/isEmailValid';
import isPhoneValid from '../../util/isPhoneValid';

const CompleteInvoiceBaseForm = ({ type }) => {
	const { createInvoice, getInvoicePreview, clearInvoicePreview } = useContext(InvoiceContext);
	const history = useHistory();
	const notify = useNotifications();
	const countries = useCountries();

	const [amount, setAmount] = useState();
	const [currency, setCurrency] = useState('EUR');
	const [invoicingCategory, setInvoicingCategory] = useState('world');
	const [reference, setReference] = useState();
	const [email, setEmail] = useState();
	const [legalName, setLegalName] = useState();
	const [isCompany, setIsCompany] = useState(false);
	const [vatNumber, setVatNumber] = useState();
	const [phone, setPhone] = useState();
	const [country, setCountry] = useState();
	const [city, setCity] = useState();
	const [zipCode, setZipCode] = useState();
	const [addressNumber, setAddressNumber] = useState();
	const [address, setAddress] = useState();
	const [generatingInvoice, setGeneratingInvoice] = useState(false);

	const isAmountCorrect = () => !isNaN(amount) && amount > 0;

	const isEmailCorrect = () => email && isEmailValid(email);

	const isPhoneCorrect = () => phone && isPhoneValid(phone);

	const isFormCorrect = () => {
		return (
			isAmountCorrect() &&
			isEmailCorrect() &&
			(!phone || isPhoneCorrect()) &&
			currency &&
			invoicingCategory &&
			reference &&
			legalName &&
			country &&
			city &&
			zipCode &&
			addressNumber &&
			address &&
			(!isCompany || vatNumber)
		);
	};

	useEffect(() => {
		if (!isFormCorrect()) return clearInvoicePreview();
		const body = {
			type,
			amount,
			currency,
			invoicing_category: invoicingCategory,
			reference,
			legal_name: legalName,
			email,
			phone,
			country,
			city,
			zip_code: zipCode,
			address_number: addressNumber,
			address,
			is_company: Boolean(isCompany),
			vat_number: vatNumber,
		};
		getInvoicePreview(body);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		address,
		addressNumber,
		amount,
		city,
		country,
		currency,
		email,
		legalName,
		isCompany,
		vatNumber,
		invoicingCategory,
		phone,
		reference,
		zipCode,
	]);

	const generateInvoice = async () => {
		try {
			setGeneratingInvoice(true);
			const invoice = {
				type,
				amount,
				currency,
				invoicing_category: invoicingCategory,
				reference,
				legal_name: legalName,
				email,
				phone,
				country,
				city,
				zip_code: zipCode,
				address_number: addressNumber,
				address,
				is_company: Boolean(isCompany),
				vat_number: vatNumber,
			};
			await createInvoice(invoice);
			setGeneratingInvoice(false);
			notify.success('Invoice issued correctly.');
			history.push('/invoices');
		} catch (err) {
			console.error(err);
			setGeneratingInvoice(false);
			notify.error('There was an issue generating the invoice.');
		}
	};

	return (
		<>
			<Text size="lg">Customer</Text>
			<Grid container style={{ marginTop: '10px' }}>
				<Grid item>
					<CheckBox selected={isCompany} onClick={setIsCompany} />
				</Grid>
				<Grid item>
					<Text>Is a company?</Text>
				</Grid>
			</Grid>
			{isCompany && (
				<Input
					label="VAT number"
					placeholder="AA123456789"
					value={vatNumber}
					isValid={vatNumber}
					errorLabels={['Missing vat number']}
					handleChange={e => setVatNumber(e.target.value)}
				/>
			)}
			<Input
				label="Legal Name"
				placeholder="Doe"
				value={legalName}
				isValid={legalName}
				errorLabels={['Missing legal name']}
				handleChange={e => setLegalName(e.target.value)}
			/>
			<Input
				label="Email"
				placeholder="example@mail.org"
				value={email}
				isValid={isEmailCorrect()}
				errorLabels={['Invalid email']}
				handleChange={e => setEmail(e.target.value)}
			/>
			<PhoneInput
				label="Phone"
				value={phone}
				isValid={isPhoneCorrect()}
				errorLabels={['Invalid phone']}
				handleChange={({ number }) => setPhone(number)}
			/>
			<Select
				fullWidth
				options={invoicingCategories}
				label="Invoicing Category"
				placeholder="Select a value..."
				handleChange={e => setInvoicingCategory(e.target.value)}
				errorLabels={['Please select an option']}
				value={invoicingCategory}
				isValid={invoicingCategory}
			/>

			<Text size="lg">Address</Text>
			<Input
				label="Address"
				placeholder="Shelby St."
				value={address}
				isValid={address}
				errorLabels={['Missing address']}
				handleChange={e => setAddress(e.target.value)}
			/>
			<Input
				label="Address Number"
				placeholder="123"
				value={addressNumber}
				isValid={addressNumber}
				errorLabels={['Missing address number']}
				handleChange={e => setAddressNumber(e.target.value)}
			/>
			<Input
				label="ZIP Code"
				placeholder="AB4456"
				value={zipCode}
				isValid={zipCode}
				errorLabels={['Missing zip code']}
				handleChange={e => setZipCode(e.target.value)}
			/>
			<Input
				label="City"
				placeholder="New York"
				value={city}
				isValid={city}
				errorLabels={['Missing city']}
				handleChange={e => setCity(e.target.value)}
			/>
			<Select
				fullWidth
				options={countries.map(({ title, _id }) => ({ value: title, label: title }))}
				label="Country"
				placeholder="Select a value..."
				handleChange={e => setCountry(e.target.value)}
				errorLabels={['Please select an option']}
				value={country}
				isValid={country}
			/>

			<Text size="lg">Additional information</Text>
			<Select
				fullWidth
				options={currencies.map(c => ({ value: c, label: c }))}
				label="Currency"
				placeholder="Select a value..."
				handleChange={e => setCurrency(e.target.value)}
				errorLabels={['Please select an option']}
				value={currency}
				isValid={currency}
			/>
			<NumericInput
				label="Amount"
				placeholder="0.00"
				isValid={isAmountCorrect()}
				value={amount}
				errorLabels={['Invalid amount']}
				handleChange={e => setAmount(e.target.value)}
			/>
			<Input
				multiline
				label="Reference"
				placeholder="Write a desccription of the charge..."
				value={reference}
				isValid={reference}
				errorLabels={['Missing reference']}
				handleChange={e => setReference(e.target.value)}
			/>
			<Button disabled={!isFormCorrect() || generatingInvoice} onClick={generateInvoice}>
				Create invoice
			</Button>
		</>
	);
};

export default CompleteInvoiceBaseForm;
