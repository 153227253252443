const currencies = [
	'ARS',
	'CAD',
	'CHF',
	'EUR',
	'GBP',
	'ISK',
	'JPY',
	'NOK',
	'NZD',
	'PLN',
	'SEK',
	'USD',
];

export default currencies;
