import React, { useState, useContext } from 'react';

import Modal from '@exploreshare/everest/base/Modal';
import Text from '@exploreshare/everest/base/Text';
import Button from '@exploreshare/everest/base/Button';

import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';

import InvoiceContext from '../../contexts/InvoiceContext';

import './DownloadModal.css';

const DownloadModal = ({ isOpen, onClose }) => {
	const { downloadCSV } = useContext(InvoiceContext);
	const [startDate, setStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
	const [endDate, setEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

	const downloadInvoices = () => {
		if (!isValid(parseISO(startDate)) || !isValid(parseISO(endDate))) return;
		const from = +startOfDay(parse(startDate, 'yyyy-MM-dd', new Date()));
		const to = +endOfDay(parse(endDate, 'yyyy-MM-dd', new Date()));
		downloadCSV({ from, to });
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose}>
			<div style={{ padding: '16px', minHeight: '200px' }}>
				<Text capitalized size={30}>
					Download invoices
				</Text>
				<div style={{ padding: '40px 0' }}>
					<label htmlFor="startDate">Issued from:&nbsp;</label>
					<input
						className="date-input"
						id="startDate"
						type="date"
						value={startDate}
						onChange={e => setStartDate(e.target.value)}
					/>
					<br />
					<label htmlFor="endDate">Issued until:&nbsp;</label>
					<input
						className="date-input"
						id="endDate"
						type="date"
						value={endDate}
						onChange={e => setEndDate(e.target.value)}
					/>
				</div>
				<Button
					primary
					onClick={downloadInvoices}
					disabled={!isValid(parseISO(startDate)) || !isValid(parseISO(endDate))}>
					Download
				</Button>
			</div>
		</Modal>
	);
};

export default DownloadModal;
