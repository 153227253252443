import React from 'react';
import { Link } from 'react-router-dom';

import Text from '@exploreshare/everest/base/Text';
import EverestLink from '@exploreshare/everest/base/Link';
import { format } from 'date-fns';

import './InvoicesTable.css';
import formatMoney from '../../util/formatMoney';

const description = invoice => {
	// eslint-disable-next-line no-unused-vars
	const [_, subType] = invoice.type.split('/');
	const niceType = [
		subType[0].toUpperCase(),
		subType.slice(1).toLowerCase().replace(/-/g, ' '),
	].join('');
	if (invoice.credit_note) return `${niceType} CN for ${invoice.parent}`;
	if (invoice.booking) return `${niceType} invoice for booking ${invoice.booking.code}`;
	return `${niceType} invoice`;
};

const InvoicesTable = ({ invoices }) => {
	return (
		<table className="invoices-table">
			<thead>
				<tr>
					<th align="left">ID</th>
					<th align="left">Date</th>
					<th align="left">Description</th>
					<th className="text-right">Debit</th>
					<th className="text-right">Credit</th>
				</tr>
			</thead>
			<tbody>
				{invoices.length ? null : (
					<tr>
						<td colSpan={5} className="text-center">
							There are no invoices to show
						</td>
					</tr>
				)}
				{invoices.map(invoice => (
					<tr key={invoice._id}>
						<td align="left">
							<Link component={EverestLink} to={`/invoices/${invoice._id}`}>
								{invoice._id}
							</Link>
						</td>
						<td align="left">
							<Text>{format(new Date(invoice.issue_date), 'dd MMM yyyy')}</Text>
						</td>
						<td align="left">
							<Text>{description(invoice)}</Text>
						</td>
						<td align="right">
							{invoice.credit_note ? null : (
								<Text color="forestgreen">
									{invoice.currency} {formatMoney(invoice.amount.full)}
								</Text>
							)}
						</td>
						<td align="right">
							{invoice.credit_note ? (
								<Text color="red">
									{invoice.currency} {formatMoney(invoice.amount.full)}
								</Text>
							) : null}
						</td>
					</tr>
				))}
			</tbody>
		</table>
	);
};

export default InvoicesTable;
