import React, { useState, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import Input from '@exploreshare/everest/base/Input';
import Button from '@exploreshare/everest/base/Button';

import Filters from './Filters';

import './Invoices.css';

import InvoiceContext from '../contexts/InvoiceContext';

import InvoiceList from './InvoiceList';
import InvoiceCreation from './InvoiceCreation';
import CreditNoteCreation from './CreditNoteCreation';
import InvoiceScreen from './InvoiceScreen';
import DownloadModal from './DownloadModal';

const Invoices = () => {
	const { loading } = useContext(InvoiceContext);
	const history = useHistory();
	const [query, setQuery] = useState('');
	const [isDownloadModalOpen, setDownloadModalOpen] = useState(false);
	const [filters, setFilters] = useState({});

	const openDownloadModal = () => setDownloadModalOpen(true);
	const closeDownloadModal = () => setDownloadModalOpen(false);

	return (
		<Switch>
			<Route path="/invoices/new/invoice">
				<InvoiceCreation />
			</Route>
			<Route path="/invoices/new/credit-note">
				<CreditNoteCreation />
			</Route>
			<Route path="/invoices/:invoiceId">
				<InvoiceScreen />
			</Route>
			<Route path="/invoices">
				<>
					<h1 className="main-title">Invoices</h1>
					<DownloadModal isOpen={isDownloadModalOpen} onClose={closeDownloadModal} />
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Button
							className="new-invoice-button"
							secondary
							onClick={() => history.push('/invoices/new/invoice')}>
							+ Add new invoice
						</Button>
						<Button className="new-invoice-button" secondary onClick={openDownloadModal}>
							Download invoices
						</Button>
					</div>
					<Filters onChange={setFilters} />
					<table className="invoices-table">
						<thead>
							<tr>
								<th style={{ width: '100px' }}>Type</th>
								<th style={{ width: '100px' }}>Invoice #</th>
								<th style={{ width: '100px' }}>Issue date</th>
								<th style={{ width: '140px' }}>To</th>
								<th style={{ width: '140px' }}>Email</th>
								<th style={{ width: '80px' }}>Booking code</th>
								<th style={{ width: '60px' }} className="text-right">
									Amount
								</th>
								<th style={{ width: '150px' }}>
									<Input
										handleChange={e => setQuery(e.target.value)}
										errorLabels={[]}
										helpLabels={[]}
										placeholder="Search"
										value={query}
										isValid={true}
									/>
								</th>
							</tr>
						</thead>
						<tbody>
							{loading ? (
								<tr>
									<td colSpan={6} style={{ textAlign: 'center' }}>
										Loading
									</td>
								</tr>
							) : (
								<InvoiceList query={query} filters={filters} />
							)}
						</tbody>
					</table>
				</>
			</Route>
		</Switch>
	);
};

export default Invoices;
