import React, { useState, useEffect, useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import Input from '@exploreshare/everest/base/Input';
import Button from '@exploreshare/everest/base/Button';
import CheckBox from '@exploreshare/everest/base/CheckBox';
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';
import PhoneInput from '@exploreshare/everest/base/PhoneInput';
import Select from '@exploreshare/everest/base/Select';

import NumericInput from '../../components/NumericInput';
import GuideSearch from '../../components/GuideSearch';

import BookingContext from '../../contexts/BookingContext';
import InvoiceContext from '../../contexts/InvoiceContext';
import { useNotifications } from '../../contexts/NotificationContext';
import { useCountries } from '../../contexts/CountriesContext';

import invoicingCategories from '../../util/invoicingCategories';
import isPhoneValid from '../../util/isPhoneValid';
import isEmailValid from '../../util/isEmailValid';
import GuideContext from '../../contexts/GuideContext';

const isBookingCodeValid = code => /^[A-Za-z0-9]{6}$/g.test(code);
const isAmountCorrect = amount => !isNaN(parseFloat(amount)) && parseFloat(amount) > 0;

const BookingBaseForm = ({ type, amountRequired, allowAlternativeRecipient }) => {
	const { fetchBookingByCode } = useContext(BookingContext);
	const { createInvoice, getInvoicePreview, clearInvoicePreview } = useContext(InvoiceContext);
	const { fetchGuideById } = useContext(GuideContext);

	const notify = useNotifications();
	const history = useHistory();
	const location = useLocation();
	const countries = useCountries();

	const [staticBookingCode, setStaticBookingCode] = useState();
	const [bookingCode, setBookingCode] = useState('');
	const [freezeBookingCode, setFreezeBookingCode] = useState(false);
	const [booking, setBooking] = useState();
	const [amount, setAmount] = useState('');
	const [useAlternativeRecipient, setUseAlternativeRecipient] = useState(false);
	const [useExistingGuide, setUseExistingGuide] = useState(true);
	const [invoicingCategory, setInvoicingCategory] = useState('world');
	const [email, setEmail] = useState();
	const [legalName, setLegalName] = useState();
	const [isCompany, setIsCompany] = useState(false);
	const [vatNumber, setVatNumber] = useState();
	const [phone, setPhone] = useState();
	const [country, setCountry] = useState();
	const [city, setCity] = useState();
	const [zipCode, setZipCode] = useState();
	const [addressNumber, setAddressNumber] = useState();
	const [address, setAddress] = useState();
	const [generatingInvoice, setGeneratingInvoice] = useState(false);
	const bookingCodeRef = useRef();

	const isEmailCorrect = () => email && isEmailValid(email);

	const isPhoneCorrect = () => phone && isPhoneValid(phone);

	const isFormCorrect = () => {
		const isAlternativeRecipientInfoOK =
			!allowAlternativeRecipient ||
			!useAlternativeRecipient ||
			(isEmailCorrect() &&
				(!phone || isPhoneCorrect()) &&
				(!isCompany || vatNumber) &&
				invoicingCategory &&
				legalName &&
				country);

		const isAmountOK = (!amountRequired && !amount) || (amount && isAmountCorrect(amount));

		return booking && isAmountOK && isAlternativeRecipientInfoOK;
	};

	const searchBooking = async ({ bookingCode }) => {
		const booking = await fetchBookingByCode(bookingCode);
		if (!booking) {
			setBooking(null);
			return notify.error('The booking does not exist');
		}
		setBooking(booking);
		return booking;
	};

	useEffect(() => {
		const { search } = location;
		const queryParams = new URLSearchParams(search);
		const queryParamBookingCode = queryParams.get('booking');
		const _searchInvoice = async () => await searchBooking({ bookingCode: queryParamBookingCode });

		if (queryParamBookingCode && isBookingCodeValid(queryParamBookingCode)) {
			setStaticBookingCode(queryParamBookingCode);
			setFreezeBookingCode(true);
			_searchInvoice();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	useEffect(() => {
		if (!bookingCode) return setBooking(null);
		if (!isBookingCodeValid(bookingCode)) {
			setBooking(null);
			return bookingCodeRef.current && bookingCodeRef.current.validate();
		}
		searchBooking({ bookingCode });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bookingCode]);

	useEffect(() => {
		if (!isFormCorrect()) return clearInvoicePreview();
		const invoice = {
			booking: booking._id,
			type,
			amount: amount ? parseFloat(amount) : undefined,
		};

		if (allowAlternativeRecipient && useAlternativeRecipient) {
			invoice.invoicing_category = invoicingCategory;
			invoice.legal_name = legalName;
			invoice.email = email;
			invoice.country = country;
			invoice.is_company = Boolean(isCompany);
			if (vatNumber) invoice.vat_number = vatNumber;
			if (city) invoice.city = city;
			if (zipCode) invoice.zip_code = zipCode;
			if (addressNumber) invoice.address_number = addressNumber;
			if (address) invoice.address = address;
		}
		getInvoicePreview(invoice);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		amount,
		booking,
		invoicingCategory,
		legalName,
		email,
		phone,
		country,
		city,
		zipCode,
		addressNumber,
		address,
		isCompany,
	]);

	const generateInvoice = async () => {
		setGeneratingInvoice(true);
		const invoice = {
			booking: booking._id,
			type,
			amount: amount ? parseFloat(amount) : undefined,
		};

		if (allowAlternativeRecipient && useAlternativeRecipient) {
			invoice.invoicing_category = invoicingCategory;
			invoice.legal_name = legalName;
			invoice.email = email;
			invoice.country = country;
			invoice.is_company = Boolean(isCompany);
			if (vatNumber) invoice.vat_number = vatNumber;
			if (phone) invoice.phone = phone;
			if (city) invoice.city = city;
			if (zipCode) invoice.zip_code = zipCode;
			if (addressNumber) invoice.address_number = addressNumber;
			if (address) invoice.address = address;
		}
		await createInvoice(invoice);
		setGeneratingInvoice(false);
		history.push('/invoices');
	};

	const selectExistingGuide = async g => {
		const guide = await fetchGuideById(g._id);
		if (guide.invoicing_category) setInvoicingCategory(guide.invoicing_category);
		if (guide.legal_name) setLegalName(guide.legal_name);
		if (guide.email) setEmail(guide.email);
		if (guide.country) setCountry(guide.country);
		if (guide.is_company) setIsCompany(guide.is_company);
		if (guide.vat_number) setVatNumber(guide.vat_number);
		if (guide.city) setCity(guide.city);
		if (guide.zip_code) setZipCode(guide.zip_code);
		if (guide.address_number) setAddressNumber(guide.address_number);
		if (guide.address) setAddress(guide.address);
	};

	return (
		<>
			<Input
				label="Booking code"
				placeholder="ABC123"
				isValid={isBookingCodeValid(staticBookingCode || bookingCode)}
				value={staticBookingCode || bookingCode}
				errorLabels={['Invalid booking code']}
				handleChange={event => setBookingCode(event.target.value)}
				ref={bookingCodeRef}
				disabled={freezeBookingCode}
			/>
			{booking ? (
				<NumericInput
					label={`Amount (${booking.currency})`}
					placeholder="0.00"
					isValid={(!amountRequired && !amount) || (amount && isAmountCorrect(amount))}
					value={amount}
					errorLabels={['Invalid amount']}
					handleChange={event => setAmount(event.target.value)}
					helpLabels={amountRequired ? [] : ['Automatically calculated if empty']}
					required={amountRequired}
				/>
			) : null}
			{allowAlternativeRecipient ? (
				<Grid container style={{ marginTop: '10px' }}>
					<Grid item>
						<CheckBox selected={useAlternativeRecipient} onClick={setUseAlternativeRecipient} />
					</Grid>
					<Grid item>
						<Text>Use alternative recipient</Text>
					</Grid>
				</Grid>
			) : null}
			{useAlternativeRecipient ? (
				<Grid container style={{ marginTop: '10px' }}>
					<Grid item>
						<CheckBox selected={useExistingGuide} onClick={setUseExistingGuide} />
					</Grid>
					<Grid item>
						<Text>Use an existing guide</Text>
					</Grid>
				</Grid>
			) : null}
			{useAlternativeRecipient && useExistingGuide ? (
				<>
					<GuideSearch onSelected={selectExistingGuide} />
				</>
			) : null}
			{useAlternativeRecipient && !useExistingGuide ? (
				<>
					<br />
					<Text size="lg">Customer</Text>
					<Grid container style={{ marginTop: '10px' }}>
						<Grid item>
							<CheckBox selected={isCompany} onClick={setIsCompany} />
						</Grid>
						<Grid item>
							<Text>Is a company?</Text>
						</Grid>
					</Grid>
					{isCompany && (
						<Input
							label="VAT number"
							placeholder="AA123456789"
							value={vatNumber}
							isValid={vatNumber}
							errorLabels={['Missing vat number']}
							handleChange={e => setVatNumber(e.target.value)}
						/>
					)}
					<Input
						label="Legal Name"
						placeholder="Doe"
						value={legalName}
						isValid={legalName}
						errorLabels={['Missing legal name']}
						handleChange={e => setLegalName(e.target.value)}
					/>
					<Input
						label="Email"
						placeholder="example@mail.org"
						value={email}
						isValid={isEmailCorrect()}
						errorLabels={['Invalid email']}
						handleChange={e => setEmail(e.target.value)}
					/>
					<PhoneInput
						label="Phone"
						value={phone}
						isValid={!phone || isPhoneCorrect()}
						errorLabels={['Invalid phone']}
						handleChange={({ number }) => setPhone(number)}
						optional
					/>
					<Select
						fullWidth
						options={invoicingCategories}
						label="Invoicing Category"
						placeholder="Select a value..."
						handleChange={e => setInvoicingCategory(e.target.value)}
						errorLabels={['Please select an option']}
						value={invoicingCategory}
						isValid={invoicingCategory}
					/>

					<Text size="lg">Address</Text>
					<Input
						label="Address"
						placeholder="Shelby St."
						value={address}
						isValid
						handleChange={e => setAddress(e.target.value)}
						optional
					/>
					<Input
						label="Address Number"
						placeholder="123"
						value={addressNumber}
						isValid
						handleChange={e => setAddressNumber(e.target.value)}
						optional
					/>
					<Input
						label="ZIP Code"
						placeholder="AB4456"
						value={zipCode}
						isValid
						handleChange={e => setZipCode(e.target.value)}
						optional
					/>
					<Input
						label="City"
						placeholder="New York"
						value={city}
						isValid
						handleChange={e => setCity(e.target.value)}
						optional
					/>
					<Select
						fullWidth
						options={countries.map(({ title, _id }) => ({ value: title, label: title }))}
						label="Country"
						placeholder="Select a value..."
						handleChange={e => setCountry(e.target.value)}
						errorLabels={['Please select an option']}
						value={country}
						isValid={country}
					/>
				</>
			) : null}
			<Button
				style={{ marginTop: '12px' }}
				fullWidth
				disabled={!isFormCorrect() || generatingInvoice}
				onClick={generateInvoice}>
				Issue invoice
			</Button>
		</>
	);
};

export default BookingBaseForm;
