import semver from 'semver';

const getCcFeePercentage = bookingVersion => {
	if (semver.gte(bookingVersion, '2.2.0')) return 2.3;
	return 1.6;
};

export const getBaseBookingPrice = booking => {
	const [minDuration, maxDuration] = booking.trip.duration;
	if (minDuration === maxDuration) {
		return booking.price_per_person * booking.people_count;
	}
	return booking.price_per_person * booking.people_count * booking.duration;
};

export const getBookingPriceWithCustomerFee = booking => {
	const { customer_fee } = booking;
	const bookingPrice = getBaseBookingPrice(booking);

	return bookingPrice / ((100 - (customer_fee || 0)) / 100);
};

export const getCustomerFee = booking => {
	const bookingPrice = getBaseBookingPrice(booking);
	const bookingPriceWithCustomerFee = getBookingPriceWithCustomerFee(booking);

	return bookingPriceWithCustomerFee - bookingPrice;
};

export const getCreditCardFee = booking => {
	const { payment_method, payment_options } = booking;
	const bookingPrice = getBaseBookingPrice(booking);
	const CREDIT_CARD_FEE = getCcFeePercentage(booking.version);

	if (semver.lt(booking.version, '2.1.0')) return 0; // Unsupported: old bookings do not have cc fee

	if (payment_method === 'wire') return 0;

	if (payment_method === 'cash') return 0;

	// Bookings 2.1.1 or newer DO NOT have credit card fee if the balance is paid directly to the guide
	if (
		semver.gt(booking.version, '2.1.0') &&
		semver.lt(booking.version, '2.2.0') &&
		payment_method === 'deposit' &&
		payment_options.rest_to_the_guide
	)
		return 0;

	// Bookings 2.2.0 add 100% credit card fee with some payment methods
	if (payment_method === 'deposit') {
		const methodsWithFee = semver.gte(booking.version, '2.2.0')
			? ['cc-wire', 'group', 'cc']
			: ['cc'];
		const fee = methodsWithFee.includes(payment_options.payment_method) ? CREDIT_CARD_FEE / 100 : 0;
		const deposit = (() => {
			if (semver.gte(booking.version, '2.2.0')) {
				if (payment_options.payment_method === 'cc' && !payment_options.rest_to_the_guide) return 1; // 100% cc fee
				return payment_options.deposit_percentage / 100; // fee only to deposit with cc
			}
			return 1 - payment_options.deposit_percentage / 100;
		})();
		return bookingPrice * deposit * fee;
	}

	return bookingPrice * (CREDIT_CARD_FEE / 100);
};

export const getTotalBookingPrice = booking => {
	return getBaseBookingPrice(booking) + getCustomerFee(booking) + getCreditCardFee(booking);
};
