export default theme => ({
	root: {
		backgroundColor: theme.palette.white,
		zIndex: 50,
		position: 'relative',
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		},
		boxShadow: `0px 1px 9px rgba(38, 46, 50, 0.08)`,
	},
	logo: {
		display: 'flex',
		alignItems: 'center',
		textDecoration: 'none',
		'& > *': {
			marginRight: 8,
		},
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		height: 56,
		padding: 16,
		alignItems: 'center',
		boxSizing: 'border-box',
		width: '100%',
		[theme.breakpoints.up('sm')]: {
			marginLeft: 40,
			marginRight: 40,
			maxWidth: 'unset',
		},
	},
	leftContent: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center',
		'> * ': {
			marginLeft: 16,
		},
	},
	home: {
		height: 36,
	},
	logout: {
		color: 'rgb(32, 119, 213)',
		cursor: 'pointer',
	},
});
