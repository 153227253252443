import React, { useState, useContext, useEffect } from 'react';
import { useParams, useHistory, Link } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs';

import Image from '@exploreshare/everest/base/Image';
import Grid from '@exploreshare/everest/base/Grid';
import Input from '@exploreshare/everest/base/Input';
import Select from '@exploreshare/everest/base/Select';
import Button from '@exploreshare/everest/base/Button';
import EverestLink from '@exploreshare/everest/base/Link';

import InvoicesTable from '../components/InvoicesTable';
import BookingsTable from '../components/BookingsTable';

import CustomerContext from '../contexts/CustomerContext';
import BookingContext from '../contexts/BookingContext';
import { useCountries } from '../contexts/CountriesContext';

import invoicingCategories from '../util/invoicingCategories';

const CustomerScreen = () => {
	const history = useHistory();
	const { customerId } = useParams();
	const {
		selectedCustomer: customer,
		loadingCustomerDetails,
		selectCustomer,
		handleChange,
		saveChanges,
	} = useContext(CustomerContext);
	const { fetchBookingsAtPage, bookingList } = useContext(BookingContext);
	const countries = useCountries();
	const [bookingsPage, setBookingsPage] = useState(0);

	const onBookingsPrevPage = () => {
		fetchBookingsAtPage({
			query: '',
			page: bookingsPage - 1,
			filters: `guide._id=${customerId}`,
		});
		setBookingsPage(bookingsPage - 1);
	};

	const onBookingsNextPage = () => {
		fetchBookingsAtPage({
			query: '',
			page: bookingsPage + 1,
			filters: `guide._id=${customerId}`,
		});
		setBookingsPage(bookingsPage + 1);
	};

	useEffect(() => {
		const fetchBookings = async () => {
			fetchBookingsAtPage({
				query: '',
				page: 0,
				filters: `lead._id:${customerId}`,
			});
		};
		selectCustomer(customerId);
		fetchBookings();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (loadingCustomerDetails) return <h1>Loading</h1>;

	if (!customer) return <div>The customer doesn't exist</div>;

	const { dirty } = customer.billing;

	return (
		<>
			<table className="guide-screen__title-table">
				<tbody>
					<tr>
						<td style={{ width: '200px' }}>
							<Image
								alt={`${customer.first_name} ${customer.last_name}`}
								className="avatar-image avatar-image-big"
								rounded
								src={customer.avatar || 'https://unsplash.it/80/80'}
							/>
						</td>
						<td>
							<h1>
								{customer.first_name} {customer.last_name}
							</h1>
							<span className="muted">{customer.email}</span>
						</td>
						<td style={{ verticalAlign: 'top', textAlign: 'right' }}>
							<Link
								onClick={e => {
									e.preventDefault();
									history.goBack();
								}}
								component={EverestLink}>
								&larr; Go back
							</Link>
						</td>
					</tr>
				</tbody>
			</table>
			<Tabs>
				<TabList>
					<Tab>Contact details</Tab>
					<Tab>Billing address</Tab>
					<Tab>Invoices</Tab>
					<Tab>Bookings</Tab>
				</TabList>

				<TabPanel>
					<h3>Contact details</h3>
					<Grid container spacing={1}>
						<Grid item xs={4}>
							<Input
								label="First name"
								placeholder="Joe"
								value={customer.billing.first_name}
								isValid={customer.billing.first_name}
								errorLabels={['Missing first name']}
								handleChange={handleChange('first_name')}
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="Last name"
								placeholder="Last name"
								value={customer.billing.last_name}
								isValid={customer.billing.last_name}
								handleChange={handleChange('last_name')}
								errorLabels={['Missing last name']}
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="Email"
								placeholder="Email"
								value={customer.email}
								isValid={customer.email}
								disabled
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="Phone"
								placeholder="Phone"
								value={customer.phone}
								isValid={customer.phone}
								disabled
							/>
						</Grid>
					</Grid>
					{(dirty.first_name || dirty.last_name) && (
						<Button primary onClick={saveChanges(['first_name', 'last_name'])}>
							Save
						</Button>
					)}
				</TabPanel>
				<TabPanel>
					<h3>Billing address</h3>
					<Grid container spacing={1}>
						<Grid item xs={8} className="no-pad-left">
							<Input
								label="Address"
								placeholder="Address"
								value={customer.billing.address}
								isValid={customer.billing.address}
								errorLabels={['Missing address']}
								handleChange={handleChange('address')}
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="Address number"
								placeholder="Address number"
								value={customer.billing.address_number}
								isValid={customer.billing.address_number}
								handleChange={handleChange('address_number')}
								errorLabels={['Missing address number']}
							/>
						</Grid>
						<Grid item xs={4} className="no-pad-left">
							<Select
								fullWidth
								options={countries.map(({ title, _id }) => ({ value: title, label: title }))}
								label="Country"
								placeholder="Select a value..."
								handleChange={handleChange('country')}
								errorLabels={['Please select an option']}
								value={customer.billing.country}
								isValid={customer.billing.country}
							/>
						</Grid>
						<Grid item xs={4}>
							<Input
								label="City"
								placeholder="City"
								value={customer.billing.city}
								isValid={customer.billing.city}
								handleChange={handleChange('city')}
								errorLabels={['Missing city']}
							/>
						</Grid>
						<Grid item xs={4} className="no-pad-left">
							<Input
								label="ZIP code"
								placeholder="ZIP code"
								value={customer.billing.zip_code}
								isValid={customer.billing.zip_code}
								handleChange={handleChange('zip_code')}
								errorLabels={['Missing ZIP code']}
							/>
						</Grid>
						<Grid item xs={4} className="no-pad-left">
							<Select
								fullWidth
								options={invoicingCategories}
								label="Invoice Category"
								placeholder="Select a value..."
								handleChange={handleChange('invoicing_category')}
								errorLabels={['Please select an option']}
								value={customer.billing.invoicing_category}
								isValid={customer.billing.invoicing_category}
							/>
						</Grid>
					</Grid>
					{(dirty.address ||
						dirty.address_number ||
						dirty.country ||
						dirty.city ||
						dirty.zip_code ||
						dirty.invoicing_category) && (
						<Button
							primary
							onClick={saveChanges([
								'address',
								'address_number',
								'country',
								'city',
								'zip_code',
								'invoicing_category',
							])}>
							Save
						</Button>
					)}
				</TabPanel>
				<TabPanel>
					<h3>Invoices</h3>
					<InvoicesTable invoices={customer.billing.invoices} />
					<Button
						style={{ marginTop: 10 }}
						onClick={() => history.push(`/invoices/new/invoice?type=customer-fee`)}>
						Issue customer fee invoice
					</Button>
					<Button
						style={{ marginTop: 10 }}
						onClick={() => history.push(`/invoices/new/invoice?type=admin-fee`)}>
						Issue admin fee invoice
					</Button>
				</TabPanel>
				<TabPanel>
					<h3>Bookings</h3>
					<BookingsTable
						bookings={bookingList}
						onPrevPage={onBookingsPrevPage}
						onNextPage={onBookingsNextPage}
					/>
				</TabPanel>
			</Tabs>
		</>
	);
};

export default CustomerScreen;
