import React, { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';

import EverestLink from '@exploreshare/everest/base/Link';
import Select from '@exploreshare/everest/base/Select';
import Header from '@exploreshare/everest/base/Header';
import Grid from '@exploreshare/everest/base/Grid';
import Text from '@exploreshare/everest/base/Text';

import ExpressEditingForm from './ExpressEditingForm';
import BookingBaseForm from './BookingBaseForm';
import CompleteInvoiceBaseForm from './CompleteInvoiceBaseForm';
import InvoiceContext from '../../contexts/InvoiceContext';
import invoiceTypes from '../../util/invoiceTypes';

const invoiceTypeLabel = invoiceTypes.reduce((memo, v) => ({ ...memo, [v.value]: v.label }), {});

const InvoiceCreation = () => {
	const { htmlInvoicePreview, apiErrorMessage } = useContext(InvoiceContext);
	const history = useHistory();
	const { search } = history.location;
	const queryParams = new URLSearchParams(search);
	const queryParamInvoiceType = queryParams.get('type');
	const [invoiceType, setInvoiceType] = useState(queryParamInvoiceType);
	if (queryParamInvoiceType && !invoiceTypeLabel[queryParamInvoiceType]) {
		return (
			<>
				<Text>Invalid invoice type</Text>
				<Link
					onClick={e => {
						e.preventDefault();
						history.goBack();
					}}
					component={EverestLink}>
					&larr; Go back
				</Link>
			</>
		);
	}

	return (
		<>
			<table className="guide-screen__title-table">
				<tbody>
					<tr>
						<td>
							<Header>
								{['Issue', (invoiceTypeLabel[invoiceType] || '').toLowerCase(), 'invoice']
									.filter(Boolean)
									.join(' ')}
							</Header>
						</td>
						<td style={{ verticalAlign: 'top', textAlign: 'right' }}>
							<Link
								onClick={e => {
									e.preventDefault();
									history.goBack();
								}}
								component={EverestLink}>
								&larr; Go back
							</Link>
						</td>
					</tr>
				</tbody>
			</table>

			<Grid container>
				<Grid item sm={4} noPaddingX style={{ paddingRight: 16 }}>
					{queryParamInvoiceType ? null : (
						<Select
							fullWidth
							options={invoiceTypes}
							label="Invoice type"
							placeholder="Select a value..."
							handleChange={e => setInvoiceType(e.target.value)}
							errorLabels={['Please select an option']}
							value={invoiceType}
							isValid={Boolean(invoiceType)}
						/>
					)}
					{invoiceType === 'customer-fee' && <BookingBaseForm type="invoice/customer-fee" />}
					{invoiceType === 'admin-fee' && (
						<BookingBaseForm type="invoice/admin-fee" amountRequired />
					)}
					{invoiceType === 'trip-commission' && (
						<BookingBaseForm type="invoice/trip-commission" allowAlternativeRecipient />
					)}
					{invoiceType === 'airbnb' && <CompleteInvoiceBaseForm type="invoice/airbnb" />}
					{invoiceType === 'other' && <CompleteInvoiceBaseForm type="invoice/other" />}
					{invoiceType === 'express-editing' && <ExpressEditingForm />}
				</Grid>
				<Grid item sm={8} noPaddingX>
					<h4>Invoice preview</h4>
					{htmlInvoicePreview ? (
						<div dangerouslySetInnerHTML={{ __html: htmlInvoicePreview }} />
					) : (
						<>
							<Text>Fill out the details to see the invoice preview</Text>
							{apiErrorMessage && (
								<>
									<hr style={{ margin: '10px 0 20px' }} />
									<Text>Can't preview this invoice:</Text>
									<Text size={24} color="#d62b2b">
										{apiErrorMessage}
									</Text>
								</>
							)}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default InvoiceCreation;
