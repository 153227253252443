import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import format from 'date-fns/format';

import Button from '@exploreshare/everest/base/Button';
import Select from '@exploreshare/everest/base/Select';

import NumericInput from '../../components/NumericInput';

import TripSearch from '../../components/TripSearch';
import GuideSearch from '../../components/GuideSearch';
import DateInput from '../../components/DateInput';

import InvoiceContext from '../../contexts/InvoiceContext';
import { useNotifications } from '../../contexts/NotificationContext';

const isAmountCorrect = amount => !isNaN(amount) && amount > 0;
const isDateCorrect = date => !isNaN(new Date(date).getTime());

const ExpressEditingForm = () => {
	const { createInvoice, getInvoicePreview, clearInvoicePreview } = useContext(InvoiceContext);
	const history = useHistory();
	const [type, setType] = useState('trip');
	const [trip, setTrip] = useState();
	const [guide, setGuide] = useState();
	const [amount, setAmount] = useState();
	const [requestDate, setRequestDate] = useState(format(new Date(), 'yyyy-MM-dd'));
	const [publicationDate, setPublicationDate] = useState(format(new Date(), 'yyyy-MM-dd'));
	const [generatingInvoice, setGeneratingInvoice] = useState(false);
	const notify = useNotifications();

	useEffect(() => {
		if (
			(!trip && !guide) ||
			!isAmountCorrect(amount) ||
			!isDateCorrect(requestDate) ||
			!isDateCorrect(publicationDate)
		) {
			return clearInvoicePreview();
		}
		const body = {
			type: 'invoice/express-editing',
			amount,
			publication_date: +new Date(publicationDate),
			request_date: +new Date(requestDate),
		};
		if (trip) body.trip = trip.post_id;
		if (guide) body.guide = guide._id.toString();
		getInvoicePreview(body);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [amount, publicationDate, requestDate, trip, guide]);

	const onTripSelected = trip => {
		setTrip(trip);
	};

	const onGuideSelected = guide => {
		setGuide(guide);
	};

	const onTypeChanged = event => {
		setTrip(null);
		setGuide(null);
		setType(event.target.value);
	};

	const generateInvoice = async () => {
		try {
			setGeneratingInvoice(true);
			const invoice = {
				type: 'invoice/express-editing',
				amount,
				publication_date: +new Date(publicationDate),
				request_date: +new Date(requestDate),
			};
			if (trip) invoice.trip = trip.post_id;
			if (guide) invoice.guide = guide._id.toString();
			await createInvoice(invoice);
			setGeneratingInvoice(false);
			notify.success('Invoice issued correctly.');
			history.push('/invoices');
		} catch (err) {
			console.error(err);
			setGeneratingInvoice(false);
			notify.error('There was an issue generating the invoice.');
		}
	};

	return (
		<>
			<Select
				label="Type express editing invoice"
				options={[
					{ value: 'trip', label: 'Trip' },
					{ value: 'profile', label: 'Profile' },
				]}
				placeholder="Select a value..."
				handleChange={onTypeChanged}
				value={type}
				isValid
			/>
			{type === 'trip' && <TripSearch onSelected={onTripSelected} />}
			{type === 'profile' && <GuideSearch onSelected={onGuideSelected} />}
			{trip || guide ? (
				<>
					<DateInput
						label="Request date"
						value={requestDate}
						onChange={e => setRequestDate(e.target.value)}
						isValid={() => true}
					/>
					<DateInput
						label="Publication date"
						value={publicationDate}
						onChange={e => setPublicationDate(e.target.value)}
						isValid={() => true}
					/>
					<NumericInput
						label={`Amount (${trip ? trip.trip_currency : 'EUR'})`}
						placeholder="0.00"
						isValid={isAmountCorrect(amount)}
						value={amount}
						errorLabels={['Invalid amount']}
						handleChange={event => setAmount(event.target.value)}
					/>
					<Button
						disabled={!isAmountCorrect(amount) || generatingInvoice}
						onClick={generateInvoice}>
						Create invoice
					</Button>
				</>
			) : null}
		</>
	);
};

export default ExpressEditingForm;
