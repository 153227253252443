import React, { createContext, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import AuthContext from './AuthContext';

import objectSize from '../util/objectSize';
import indexBy from '../util/indexBy';

import { fetchGuideList, fetchGuide, updateGuide, fetchInvoiceFromGuide } from '../data/guides';
import { useNotifications } from './NotificationContext';

const GuideContext = createContext({
	guideList: [],
	guidesMap: {},
	loading: true,
	loadingGuideDetails: true,
	selectedGuide: null,
	selectGuide: () => {},
	deselectGuide: () => {},
	handleChange: () => {},
	saveChanges: () => {},
	fetchGuideById: () => {},
	getInvoicesFromGuide: () => {},
});

export default GuideContext;

export const GuideProvider = ({ children }) => {
	const { credentials } = useContext(AuthContext);
	const history = useHistory();
	const notify = useNotifications();
	const [guideList, setGuideList] = useState([]);
	const [selectedGuide, setSelectedGuide] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingGuideDetails, setLoadingGuideDetails] = useState(true);

	useEffect(() => {
		const fetchGuides = async () => {
			setLoading(true);
			const guideList = await fetchGuideList(credentials);
			setGuideList(guideList);
			setLoading(false);
		};
		fetchGuides();
	}, [credentials]);

	const guidesMap = indexBy(guideList, '_id');

	const getInvoicesFromGuide = async id => {
		const invoices = await fetchInvoiceFromGuide(id, credentials);
		return invoices;
	};

	const selectGuide = async guideId => {
		if (!guideId) return setSelectedGuide(null);
		setLoadingGuideDetails(true);
		const [guideData, invoices] = await Promise.all([
			fetchGuide(guideId, credentials),
			getInvoicesFromGuide(guideId),
		]);

		setSelectedGuide({ ...guideData, dirty: {}, original: guideData, invoices });
		setLoadingGuideDetails(false);
	};

	const fetchGuideById = async guideId => {
		try {
			const guide = await fetchGuide(guideId, credentials);
			return guide;
		} catch (err) {
			console.error(err);
			return null;
		}
	};

	const deselectGuide = () => {
		setSelectedGuide(null);
		setLoadingGuideDetails(true);
		history.push('/guides');
	};

	const handleChange = key => e => {
		if (!selectedGuide) return;
		setSelectedGuide({
			...selectedGuide,
			[key]: e.target.value,
			dirty: {
				...selectedGuide.dirty,
				[key]: (selectedGuide.original[key] || '') !== e.target.value,
			},
		});
	};

	const saveChanges = fields => async () => {
		if (!selectedGuide) return;
		const diff = Object.keys(selectedGuide.dirty).reduce((memo, key) => {
			if (!selectedGuide.dirty[key]) return { ...memo };
			if (!fields.includes(key)) return { ...memo };
			return { ...memo, [key]: selectedGuide[key] };
		}, {});
		if (!objectSize(diff)) return;
		try {
			const updatedGuide = await updateGuide(selectedGuide._id, diff, credentials);
			notify.success('Actualizado exitosamente');
			const resetDiff = fields.reduce((memo, key) => ({ ...memo, [key]: false }), {});
			setSelectedGuide({
				...selectedGuide,
				original: updatedGuide,
				dirty: { ...selectedGuide.dirty, ...resetDiff },
			});
		} catch (err) {
			console.error(err);
			notify.error('La actualizació ha fallado. Revise sus valores');
		}
	};

	const contextValues = {
		guideList,
		guidesMap,
		loading,
		selectGuide,
		fetchGuideById,
		selectedGuide: selectedGuide && { ...guidesMap[selectedGuide._id], billing: selectedGuide },
		loadingGuideDetails,
		deselectGuide,
		handleChange,
		saveChanges,
		getInvoicesFromGuide,
	};

	return <GuideContext.Provider value={contextValues}>{children}</GuideContext.Provider>;
};
