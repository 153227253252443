/* eslint-disable jsx-a11y/accessible-emoji */
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

import EverestLink from '@exploreshare/everest/base/Link';
import CheckBox from '@exploreshare/everest/base/CheckBox';
import Text from '@exploreshare/everest/base/Text';
import Tooltip from '@exploreshare/everest/base/Tooltip';

import Pager from '../components/Pager';
import ReadyToBillContext from '../contexts/ReadyToBillContext';

const convertAlgoliaBookingModel = booking => {
	booking._id = booking.objectID;
	return booking;
};

const ReadyToBillList = ({ query, from, to, selected, toggleSelected, toggleAll }) => {
	const history = useHistory();
	const { readyToBillList: bookings, fetchReadyToBillBookingsAtPage, loading } = useContext(
		ReadyToBillContext
	);

	useEffect(() => {
		fetchReadyToBillBookingsAtPage(
			query,
			startOfDay(parseISO(from)).getTime(),
			endOfDay(parseISO(to)).getTime(),
			0
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [query, from, to]);

	const nextPage = nextPage => {
		fetchReadyToBillBookingsAtPage(
			query,
			startOfDay(parseISO(from)).getTime(),
			endOfDay(parseISO(to)).getTime(),
			nextPage
		);
	};

	const prevPage = prevPage => {
		fetchReadyToBillBookingsAtPage(
			query,
			startOfDay(parseISO(from)).getTime(),
			endOfDay(parseISO(to)).getTime(),
			prevPage
		);
	};

	const bookingListContent = (() => {
		if (loading) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						Loading
					</td>
				</tr>
			);
		}
		if (!bookings.length) {
			return (
				<tr>
					<td colSpan={6} style={{ textAlign: 'center' }}>
						It appears there are no bookings
					</td>
				</tr>
			);
		}

		return bookings.map(convertAlgoliaBookingModel).map((booking, i) => (
			<tr key={booking._id}>
				<td>
					<CheckBox
						selected={Boolean(selected.find(b => b._id === booking._id))}
						onClick={toggleSelected(booking)}
					/>
				</td>
				<td>
					{booking.state === 'B:CANCELLED' ? (
						<>
							<Text id={`warning${i}`} inline>
								⚠️
							</Text>
							&nbsp;
							<Tooltip position="top" elementID={`warning${i}`} label="This booking is cancelled" />
						</>
					) : null}
					<Link component={EverestLink} to={`/bookings/${booking._id}`}>
						{booking.code}
					</Link>
				</td>
				<td>
					<Link component={EverestLink} to={`/guides/${booking.guide._id}`}>
						{booking.guide.email}
					</Link>
				</td>
				<td>
					{booking.prices.currency} {booking.prices.totalPrice.toFixed(2)}
				</td>
				<td>
					{booking.prices.currency} {booking.prices.guideFee.toFixed(2)}
				</td>
				<td>{booking.prices.guideFeePercentage}%</td>
				<td>{format(new Date(booking.paid_date), 'dd MMM yyyy')}</td>
				<td>
					<Link
						component={EverestLink}
						to={`/invoices/new/invoice?type=trip-commission&booking=${booking.code}`}>
						Issue trip commission invoice
					</Link>
				</td>
			</tr>
		));
	})();

	return (
		<>
			{bookingListContent}
			<tr className="pager">
				<td colSpan={12}>
					<Pager onPrevPage={prevPage} onNextPage={nextPage} />
				</td>
			</tr>
		</>
	);
};

export default ReadyToBillList;
