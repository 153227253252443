import checkResponseOk from './checkResponseOk';

const { REACT_APP_LA2_API, REACT_APP_BILLING_API } = process.env;

export const fetchCustomerList = async (page, pageSize, { token }) => {
	const response = await fetch(
		`${REACT_APP_LA2_API}/api/v1/dashboard/leads/?page=${page}&page_size=${pageSize}`,
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		}
	);
	await checkResponseOk(response);
	return await response.json();
};

export const updateCustomer = async (id, delta, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/leads/${id}`, {
		method: 'PATCH',
		body: JSON.stringify(delta),
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchInvoicesFromCustomer = async (id, { token }) => {
	const response = await fetch(`${REACT_APP_BILLING_API}/v1/invoices?customer=${id}`, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`,
		},
	});
	await checkResponseOk(response);
	return await response.json();
};

export const fetchCustomer = async (id, { token }) => {
	const fetchCustomerBillingData = async () => {
		const response = await fetch(`${REACT_APP_BILLING_API}/v1/leads/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		return response.json();
	};

	const fetchCustomerLAData = async () => {
		const response = await fetch(`${REACT_APP_LA2_API}/api/v1/dashboard/leads/brief/${id}`, {
			headers: {
				Authorization: `Bearer ${token}`,
			},
		});
		await checkResponseOk(response);
		return response.json();
	};

	const [billingData, leadappData] = await Promise.all([
		fetchCustomerBillingData(),
		fetchCustomerLAData(),
	]);

	return {
		...leadappData.lead,
		billing: billingData,
		bookings: {
			pending: leadappData.pending,
			completed: leadappData.completed,
			cancelled: leadappData.cancelled,
		},
	};
};
