import React, { createContext, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import algoliasearch from 'algoliasearch/lite';

import AuthContext from './AuthContext';

import { updateCustomer, fetchCustomer, fetchInvoicesFromCustomer } from '../data/customers';
import { useNotifications } from './NotificationContext';

import objectSize from '../util/objectSize';

const { REACT_APP_ALGOLIA_APP_ID, REACT_APP_ALGOLIA_API_KEY, REACT_APP_LA2_API } = process.env;

const CustomerContext = createContext({
	customerList: [],
	loading: true,
	loadingCustomerDetails: true,
	selectedCustomer: null,
	selectCustomer: () => {},
	deselectCustomer: () => {},
	fetchCustomersAtPage: () => {},
	handleChange: () => {},
	saveChanges: () => {},
	getInvoicesFromCustomer: () => {},
});

export default CustomerContext;

const PAGE_SIZE = 20;

export const CustomerProvider = ({ children }) => {
	const { credentials } = useContext(AuthContext);
	const history = useHistory();
	const notify = useNotifications();
	const [customerList, setCustomerList] = useState([]);
	const [selectedCustomer, setSelectedCustomer] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingCustomerDetails, setLoadingCustomerDetails] = useState(true);

	const fetchCustomersAtPage = async (search, page) => {
		setLoading(true); // -> this makes the page flicker and scroll up

		const customerList = await fetch(`${REACT_APP_LA2_API}/api/v3/lead/search`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${credentials.token}`,
			},
			body: JSON.stringify({
				search,
			}),
		});

		setCustomerList(await customerList.json());
		setLoading(false);
	};

	const getInvoicesFromCustomer = async id => {
		const invoices = await fetchInvoicesFromCustomer(id, credentials);
		return invoices;
	};

	const selectCustomer = async customerId => {
		if (!customerId) return setSelectedCustomer(null);
		setLoadingCustomerDetails(true);
		try {
			const [customer, invoices] = await Promise.all([
				fetchCustomer(customerId, credentials),
				getInvoicesFromCustomer(customerId),
			]);
			customer.billing.original = { ...customer.billing };
			customer.billing.dirty = {};
			customer.billing.invoices = invoices;
			setSelectedCustomer(customer);
		} catch (e) {
			setSelectedCustomer(null);
			console.error(e);
		}
		setLoadingCustomerDetails(false);
	};

	const deselectCustomer = () => {
		setSelectedCustomer(null);
		setLoadingCustomerDetails(true);
		history.push('/customers');
	};

	const handleChange = key => e => {
		if (!selectedCustomer) return;
		const billing = { ...selectedCustomer.billing };
		billing[key] = e.target.value;
		billing.dirty[key] = (selectedCustomer.billing.original[key] || '') !== e.target.value;
		const customer = {
			...selectedCustomer,
			billing,
		};
		setSelectedCustomer(customer);
	};

	const saveChanges = fields => async () => {
		if (!selectedCustomer) return;
		const diff = Object.keys(selectedCustomer.billing.dirty).reduce((memo, key) => {
			if (!selectedCustomer.billing.dirty[key]) return { ...memo };
			if (!fields.includes(key)) return { ...memo };
			return { ...memo, [key]: selectedCustomer.billing[key] };
		}, {});
		if (!objectSize(diff)) return;
		try {
			const updatedGuide = await updateCustomer(selectedCustomer._id, diff, credentials);
			notify.success('Actualizado exitosamente');
			const resetDiff = fields.reduce((memo, key) => ({ ...memo, [key]: false }), {});
			setSelectedCustomer({
				...selectedCustomer,
				original: updatedGuide,
				dirty: { ...selectedCustomer.dirty, ...resetDiff },
			});
		} catch (err) {
			console.error(err);
			notify.error('La actualizació ha fallado. Revise sus valores');
		}
	};

	const contextValues = {
		customerList,
		loading,
		selectCustomer,
		selectedCustomer,
		loadingCustomerDetails,
		deselectCustomer,
		fetchCustomersAtPage,
		handleChange,
		saveChanges,
		getInvoicesFromCustomer,
	};

	return <CustomerContext.Provider value={contextValues}>{children}</CustomerContext.Provider>;
};
