import React, { useContext } from 'react';
import './root-styles/App.css';
import { BrowserRouter, HashRouter } from 'react-router-dom';

import AuthContext, { AuthProvider } from './contexts/AuthContext';

import Dashboard from './Dashboard';
import AuthScreen from './AuthScreen';
import { CountryProvider } from './contexts/CountriesContext';
import { NotificationProvider } from './contexts/NotificationContext';

const Router = process.env.NODE_ENV === 'production' ? HashRouter : BrowserRouter;

const App = () => {
	const { credentials } = useContext(AuthContext);
	if (!credentials) return <AuthScreen />;
	return (
		<Router>
			<CountryProvider>
				<Dashboard />
			</CountryProvider>
		</Router>
	);
};

const AppWrapper = () => (
	<AuthProvider>
		<NotificationProvider>
			<App />
		</NotificationProvider>
	</AuthProvider>
);

export default AppWrapper;
