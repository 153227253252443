import React from 'react';

import Text from '@exploreshare/everest/base/Text';

const SummaryItem = ({ title, content, bold = false, ...rest }) => (
	<div style={{ display: 'flex', justifyContent: 'space-between' }} {...rest}>
		<div>
			<Text bold={bold} color={bold ? 'black' : 'grey'} size="14px">
				{title}
			</Text>
		</div>
		<div>
			<Text bold={bold} size="14px">
				{content}
			</Text>
		</div>
	</div>
);

export default SummaryItem;
